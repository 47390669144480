.red_border{
  border: 2px solid red;
  border-radius: 10px !important;
}

.topheader{
  margin: 10px 4pc;
  .container-fluid{
    ul{
      padding: 0;
      display: flex;
      gap: 10px;

      a{
        background-color: #FF5050;
        padding:5px 10px;
        border-radius: 10px;
        color: #fff;
      }
      
    }
  }

} 

header {
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;

  .topbar,
  .navbar {
    margin: 10px 4pc 0;
    // border-top: 2px solid #c4c4c4;
    // border-bottom: 2px solid #c4c4c4;
  }

  
  &.change-header {

    //home-page-header
    .navbar {
      // border: 1px solid #bdc0c1;
      // border-width: 2px 0 2px;

      .nav-link {
        color: #fff !important;
        font-weight: 300;
        letter-spacing: 2px;
      }
    }
  }
}

.navbar-toggler:focus {
  box-shadow: none;
}

.topbar {
  display: none;

  &-inner {
    display: flex;
    gap: 20px;

    .icon {
      color: #fff;
    }

    p {
      line-height: normal;
    }
  }
}

.social-links {
  display: flex;
  align-items: center;
  gap: 15px;
}

.navbar {
  padding: 1pc 0;

  .nav-link {
    font-size: 18px;
    color: #000;
    letter-spacing: 1px;
  }

  &-nav,
  &-right {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}

.main-slider {
  height: 100vh;
  position: relative;
  background-image: url("../images/slides/slide6.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  &:before {
    // content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, #ffffff00 20%, #00000052 88%);
    z-index: 9;
  }

  &-box {
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-content {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-align: center;
    z-index: 15;
    padding-bottom: 7%;
    position: relative;

    .title-section {
      // margin-bottom: 4pc;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;

      h1.heading {
        font-weight: 600;
        font-size: 35px;
        margin-bottom: 1pc;
      }

      h1,
      p {
        color: #fff;
      }
    }
    .mobile-search {
      // position: absolute;
      // bottom: 2pc;
      // left: 50%;
      // transform: translate(-50%, 0%);
      // width: 90%;
      margin-top: 4pc;
      .form-control {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 11px 1pc;
        background: #ffffffa6;
        backdrop-filter: blur(2px);
      }
    }
  }
}

.main-slider-tabs {
  ul.tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    margin-bottom: 3pc;

    button {
      background: transparent;
      border: 0;
      color: #fff;
      display: flex;
      align-items: center;
      gap: 15px;
      font-size: 20px;
      padding: 10px;

      &.active {
        border-bottom: 2px solid #fff;
      }

      img {
        height: auto;
        width: 35px;
        object-fit: contain;
      }
    }
  }

  .tab-content {
    .form-control {
      &.main-search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 1pc;

        &>input[type="search"] {
          font-size: 22px;
        }
      }

      &.inner-search {
        display: flex;
        align-items: center;
        gap: 10px;
        border: 0;
        border-radius: 6px;

        .icon {
          font-size: 22px;
        }
      }
    }

    .travel-info {
      display: flex;
      width: 100%;
      padding: 0 1pc;
      justify-content: space-between;

      &>li {
        // display: flex;
        // justify-content: center;
        border-right: 1px solid #dad8d8;
        padding: 0px 1pc;
        gap: 40px;
        align-items: flex-start;
        width: -webkit-fill-available;
        cursor: pointer;
        position: relative;

        h5 {
          font-size: 18px;
        }

        p.text {
          white-space: nowrap;
          margin-bottom: 0;
          font-size: 15px;
        }
      }

      .search-bar {
        bottom: -14px;
        position: absolute;
        z-index: 30;
        right: 0;
        width: 100%;
        box-shadow: 0 0 15px #00000020;
      }
    }

    .traveller {
      background-color: #fff;
      padding: 1pc;
      width: 681px !important;
      border-radius: 6px;
      bottom: auto !important;
      top: 40px;

      &.room-guest {
        bottom: -210px !important;
        height: 240px;
        overflow: hidden scroll;
      }

      p.text {
        text-align: left;
        font-size: 13px;
        font-weight: 600;
      }

      ul.number {
        display: flex;
        box-shadow: 0 0 10px #00000020;
        border-radius: 5px;
        width: max-content;

        li {
          padding: 8px 1pc;
          font-weight: 500;
          font-size: 13px;

          &.active {
            background: $red;
            color: #fff;
            border-radius: 5px;
          }
        }
      }
    }
  }
}

.custum-dropdown {
  position: absolute;
  height: 300px;
  width: max-content;
  text-align: left;
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 0 15px #00000020;
  z-index: 30;
  overflow: hidden scroll;

  &>p.text-muted {
    padding: 10px;
  }

  p {
    line-height: normal;
    margin: 0;

    &.text-muted {
      font-weight: 500;
      font-size: 13px;
    }
  }

  .list {
    border-top: 1px solid #eee;

    li {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 5px 10px;
      border-bottom: 1px solid #eee;

      p {
        &.text-muted {
          font-size: 12px;
        }

        &.text-dark {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
}

.date-picker-module {
  cursor: pointer;
  font-family: $lexend;

  .react-daterange-picker {
    &__button {
      display: none;
    }

    &__wrapper {
      border: 0;
    }

    &__calendar {
      inset: auto auto -250px -70px !important;
    }
  }

  .react-calendar {
    border: 0;
    border-radius: 6px;
    box-shadow: 0 0 15px #0000003b;


    &__navigation {
      border-radius: 6px 6px 0 0;
      background: #b7b7b7;

      button {
        color: #fff;
        font-weight: 600;

        &:enabled:hover {
          background: $red;
        }
      }
    }

    &__tile {
      &--active {
        background: #f99192;
        color: white;
        font-weight: 600;
      }

      &--hasActive {
        background: #ff5659;
        color: #fff;
        font-weight: 600;
      }
    }
  }
}

.why-us {
  background: linear-gradient(90deg,
      #b55eb775 0%,
      #54b4b467 48%,
      #6957af68 100%);
  padding: 20px 0;

  li {
    justify-content: center;
    display: flex;
    align-items: center;
    gap: 20px;

    img {
      width: auto;
      height: 50px;
      object-fit: contain;
    }

    h5 {
      margin: 0;
    }
  }
}

.best-off-box {
  position: relative;
  overflow: hidden;
  margin: 0 0 15px 15px;

  .image {
    height: 350px;
    overflow: hidden;
    border-radius: 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
      transition: all 0.5s;
    }
  }

  &:hover {
    img {
      transform: scale(1.3);
      transition: all 0.5s;
    }
  }

  .content {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, #00000000 50%, #000000a3 100%);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 1pc;
    border-radius: 20px;
    text-align: center;

    h4,
    p {
      transition: all 0.5s;
      color: #fff;
      margin: 0;
    }
  }

  &.trending-destination {
    &-left {
      .image {
        height: 250px;
      }
    }

    &-right {
      .image {
        height: calc((250px * 2) + (15px));
      }
    }
  }

  &.most-viewed {
    &-left {
      .image {
        height: 250px;
      }
    }

    &-right {
      .image {
        height: calc((250px * 2) + (15px));
      }
    }
  }
}

.adventure {
  .title-section {
    padding: 1pc;
    border-radius: 10px;
    height: 200px;
    display: flex;
    align-items: center;
  }

  &-box {
    text-align: center;

    .image {
      border-radius: 50%;
      overflow: visible;

      img {
        height: 130px;
        width: 130px;
        object-fit: cover;
      }
    }

    h5 {
      margin: 1pc 0 0;
    }
  }
}

.about-tour-box {
  .content {
    padding: 2pc;
  }
}

.awards {
  padding: 3pc;
  border-radius: 10px;

  li:first-child {
    margin-top: 2pc;
  }

  .image {
    margin-bottom: 3pc;
    text-align: center;

    &:last-child {
      margin: 0;
    }

    img {
      width: 100px;
      height: auto;
      object-fit: contain;
      transition: all 0.5s;

      &:hover {
        transform: scale(1.1);
        transition: all 0.5s;
      }
    }
  }
}

.test-award-mid {
  border-radius: 10px;
  max-height: 495px;
  height: 100%;
}

.testimonials {
  background-image: url("../images/testimonial_bg.png");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 3pc 2pc;
  // box-shadow: 0 0 20px #00000020;
  text-align: center;
  position: relative;

  &-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 450px;
    align-items: center;

    .image {
      img {
        height: 140px;
        width: 140px;
        border-radius: 50%;
        margin-bottom: 1pc;
      }
    }

    p {
      color: #000;
    }

    .content {
      background-image: url("../images/quote.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;

      p {
        padding: 1pc 2pc;
      }
    }
  }
}

.brand {
  img {
    width: 100%;
    height: 145px;
    object-fit: contain;
  }

  .image {
    text-align: center;
  }
}

.footer {
  // background-color: #fff;
  // box-shadow: 0 0 25px #0000000f;
  border-image: url("../images/decor/stamp.png") 17 round;
  -webkit-border-image: url("../images/decor/stamp.png") 17 round;
  filter: drop-shadow(4px 5px 12px rgba(0, 0, 0, 0.07));
  border-width: 13px;
  border-style: solid;
  border-radius: 20px;
  padding: 4pc 0;
  margin: 0 10pc;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url("../images/decor/bg2.jpg");
    background-size: cover;
    background-position: center bottom;
    bottom: 0pc;
    left: 0;
    z-index: -1;
  }

  .icon {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      width: auto;
      height: 30px;
      object-fit: contain;
    }
  }

  ul.links li {
    margin-bottom: 10px;

    a {
      color: #797979;

      &:hover {
        color: $red;
      }
    }
  }

  label {
    color: #000;
    font-weight: 500;
    font-size: 15px;
  }

  h4.heading {
    margin-bottom: 1.5pc;
    text-transform: capitalize;
    white-space: nowrap;
  }

  .destinations {
    img {
      width: 100%;
      height: 100px;
      object-fit: cover;

      &:hover {
        transform: scale(1.1);
        transition: all 0.5s;
      }
    }
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 3pc;

    .line {
      width: 100%;
      background: #ddd;
    }
  }
}

ul.social-links {
  margin-top: 1pc;

  li {
    margin: 0;
  }

  img {
    width: auto;
    height: 25px;
    object-fit: contain;
  }
}

footer {
  position: relative;
  overflow: hidden;
  padding-top: 1pc;

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    // background-color: #454f5b;
    background-image: url("../images/footer.jpg");
    background-size: cover;
    // background-position: center bottom;
    background-attachment: fixed;
    bottom: -6pc;
    left: 0;
    z-index: -1;
  }
}

.copyright {
  padding: 2pc 0;

  p,
  a {
    color: #fff;
  }
}

.questions {
  min-height: 100vh;
  overflow: hidden scroll;
  background-image: url("../images/travelers.png");
  background-position: center 101%;
  background-repeat: repeat no-repeat;
  background-size: 12pc;
  position: relative;
  padding: 2pc 5pc 5pc 5pc;

  &:before {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-image: url("../images/decor/3.jpg");
    background-size: cover;
    content: "";
    z-index: -1;
  }

  .container-fluid {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &-inner {
    margin: 4pc 0;

    .search-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0 0 20px #00000020;
    }

    .destination-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;

      .best-off-box {
        width: calc(20% - (15px));
        border-radius: 10px;

        .content,
        .image,
        .image img {
          border-radius: 10px;
        }

        .image {
          height: 245px;
        }

        .content {
          background: linear-gradient(180deg, #00000000 0%, #000000c9 100%);

          p {
            font-size: 14px;
          }

          h4 {
            font-size: 22px;
          }
        }

        &:hover {
          .content {
            p {
              font-size: 14px !important;
            }

            h4 {
              font-size: 22px !important;
            }
          }
        }
      }
    }
  }
}

ul.travel-month {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  li {
    width: calc(16.6% - 25px);
    margin: 0 25px 25px 0px !important;
    background: #fff;
    border-radius: 10px;
    padding: 1pc;
    box-shadow: 0 0 20px #00000020;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    img {
      width: auto;
      height: 45px;
      object-fit: contain;
      margin: 16px 0;
    }

    p {
      margin: 0;

      &.our-pick {
        color: #ff5050;
      }

      &.second-best {
        color: #eeba00;
      }

      &.off-season {
        color: #888888;
      }
    }
  }
}

.travel-purpose {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  li {
    width: calc(25% - 25px);
    margin: 0 25px 25px 0px;
    background: #fff;
    border-radius: 10px;
    padding: 1pc 1pc;
    box-shadow: 0 0 20px #00000020;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    img {
      width: 100%;
      height: 150px;
      object-fit: cover;
      border-radius: 10px;
    }

    h5 {
      margin: 1pc 0 0px;
      font-size: 17px;
    }
  }
}

.ques-2 {
  .date-picker-module {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: #00000080;

    background-color: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    
    z-index: 999;

    .react-daterange-picker {
      &__wrapper {
        display: none;
      }

      &__calendar {
        position: static !important;
      }
    }
  }
}

.how-many-people {
  margin-bottom: 3pc;

  .swiper {
    box-shadow: 0 0 10px #00000021;
    border-radius: 5px;
    background-color: #fff;

    &-slide {
      text-align: center;

      &:nth-child(odd) {
        .num {
          background-color: #f4f4f4;
        }
      }
    }
  }

  .num {
    padding: 8px 1pc;
    font-weight: 500;
    cursor: pointer;
    color: #000;

    &.active {
      background: #ff5050;
      color: #fff;
      border-radius: 5px;
    }
  }
}

.select-age {
  &>.d-flex{
    column-gap: 25px;
    row-gap: 25px;
  }
  label {
    display: flex;
    align-items: center;
    gap: 20px;

    h5 {
      white-space: nowrap;
    }
  }

  &-box {
    background: #fff;
    border-radius: 10px;
    padding: 10px 1pc;
    box-shadow: 0 0 20px #00000020;
    width: max-content;
    // margin: 0 25px 25px 0;
    .form-check-input {
      height: 40px;
      width: 40px;
      background-color: #f4f4f4;
      border: 0;
      margin: 0;
      &:checked {
        background-color: $red;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.timeline {
  display: flex;
  align-items: center;
  justify-content: center;

  li {
    margin-right: 150px;
    position: relative;

    &.half {
      .dot {
        background-color: $red;
        height: 30px;
        width: 30px;
        position: relative;

        &:before {
          content: "";
          height: 15px;
          width: 15px;
          background-color: #fff;
          border-radius: 50%;
          position: absolute;
          z-index: 5;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .half-filled {
        position: absolute;
        height: 100px;
        width: 110px;
        top: 50%;
        transform: translateY(-50%);
        left: 0%;

        .half-line {
          background-color: $red;
          height: 2px;
          width: 100%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
        }

        img {
          position: absolute;
          top: 50%;
          transform: translateY(-100%);
          right: -5px;
          height: auto;
          width: 50px;
          object-fit: contain;
        }
      }

      &:last-child {
        .half-line {
          display: none;
        }

        .half-filled {
          left: -3pc;

          img {
            right: auto;
            left: 0;
          }
        }
      }
    }

    &:not(:last-child) {
      .empty-line {
        background-color: #d9d9d9;
        height: 2px;
        width: 150px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 100%;
      }

      .full-filled {
        background-color: $red;
        height: 2px;
        width: 150px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 100%;
        z-index: 4;
      }

      &.full {
        .dot {
          background-color: $red;
        }
      }
    }
  }

  .dot {
    height: 20px;
    width: 20px;
    background-color: #d9d9d9;
    border-radius: 50%;
    cursor: pointer;
  }

  &.verticle-timeline {
    flex-direction: column;

    li {
      margin-right: 0;
      margin-bottom: 100px;

      &:not(:last-child) {
        .empty-line {
          width: 2px;
          height: 135px;
          top: 0;
          left: 50%;
          transform: translate(-50%, 0px);
        }

        .full-filled {
          width: 2px;
          height: 135px;
          top: 0;
          left: 50%;
          transform: translate(-50%, 0px);
        }
      }

      .dot {
        z-index: 4;
      }

      &.half {
        .half-filled {
          width: 2px;
          height: 75px;
          top: 0;
          left: 50%;
          transform: translate(-50%, 0px);

          .half-line {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.ques-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  // background: #fff;
  padding: 1pc 0;

  button {
    border-width: 2px;

    &.bg-white {
      border-color: $red;
      color: $red;

      &:hover {
        background-color: $red !important;
        color: #fff;
      }
    }

    &.red-bg {
      &:hover {
        background-color: #fff !important;
        color: $red;
        border-color: $red;
      }
    }
  }
}

.page-banner {
  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }

  &-1 {
    position: relative;

    // &:before {
    //   content: "";
    //   background-image: url("../images/decor/brush-down.png");
    //   background-repeat: no-repeat;
    //   position: absolute;
    //   width: 100%;
    //   bottom: 0px;
    //   left: 0;
    //   height: 30px;
    //   transform: rotate(180deg);
    //   z-index: 4;
    // }
    img {
      height: 50vh;
    }
  }

  &-2 {
    position: relative;

    // &:before {
    //   content: "";
    //   background-image: url("../images/decor/brush-down.png");
    //   background-repeat: no-repeat;
    //   position: absolute;
    //   width: 100%;
    //   bottom: 0px;
    //   left: 0;
    //   height: 30px;
    //   transform: rotate(180deg);
    //   z-index: 4;
    // }
    .image {
      margin: 0 10px 10px 0;

      &.left {
        height: calc(500px + (10px));
      }

      &.right {
        height: 250px;
      }
    }
  }

  &-3 {
    background-size: cover;
    // background-position: center;
    background-attachment: fixed;
    height: 60vh;
    display: flex;
    align-items: center;
    position: relative;

    // &:before {
    //   content: "";
    //   background-image: url("../images/decor/brush-down.png");
    //   background-repeat: no-repeat;
    //   position: absolute;
    //   width: 100%;
    //   bottom: 0px;
    //   left: 0;
    //   height: 30px;
    //   transform: rotate(180deg);
    //   z-index: 4;
    // }
    .page-banner-content {
      padding: 2pc;
      background: #00000080;
      border-radius: 20px;

      p {
        letter-spacing: 2px;
        font-size: 20px;
        font-weight: 200;
        line-height: 25px;
      }
    }
  }
}

.sort-by {
  &__value-container {
    width: 160px;
    font-size: 14px;
  }

  &__input-container {
    margin: 0;
    padding: 0;
  }

  &__control {
    min-height: 40px !important;
    border-color: #ced4daa3 !important;
  }

  &__indicator {
    padding: 0 8px;

    &-separator {
      background-color: #ced4daa3 !important;
    }
  }

  &__menu {
    // color: gray;
    font-size: 14px;
  }
}

.packages {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;

  &-left {
    width: 18%;
  }

  &-box {
    background-color: #fff;
    box-shadow: 0 0 20px #00000020;
    border-radius: 10px;
    padding: 1pc;
  }

  &-head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .search-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-color: #068a4f;
      // box-shadow: 0 0 20px #00000020;
    }
  }

  &-inner {

    // margin: 1.5pc 0;
    &-left {
      overflow: hidden;
      border-radius: 10px;

      img {
        border-radius: 10px;
        height: 100%;
        transition: all 1.5s cubic-bezier(0.18, 1.06, 0.72, -0.39);
      }
    }

    &:hover {
      .packages-inner-left img {
        transform: scale(1.4);
        transition: all 1.5s cubic-bezier(0.18, 1.06, 0.72, -0.39);
      }
    }

    &-right {
      padding: 1pc 2pc;

      p.price {
        display: flex;
        align-items: baseline;
        color: #000;
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 7px;
      }

      .heading {
        // align-items: center;
        justify-content: space-between;
        margin-bottom: 1pc;
      }

      ul.offer {
        display: flex;
        align-items: center;
        gap: 25px;
        margin-top: 1pc;

        img {
          width: auto;
          object-fit: contain;
          height: 30px;
          transition: all 0.5s;

          &:hover {
            transform: scale(1.2);
            transition: all 0.5s;
          }
        }
      }

      ul.detail {
        display: flex;
        align-items: center;
        gap: 30px;
        margin-bottom: 1pc;

        li {
          display: flex;
          align-items: baseline;
          gap: 7px;
          color: $red;
          font-weight: 600;
          font-size: 17px;

          .icon {
            font-size: 19px;
          }
        }
      }

      .itinarary {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
        margin: 1.5pc 0;

        li {
          background-color: #ffe4c3;
          color: #000;
          padding: 5px 10px;
          border-radius: 5px;
          font-weight: 500;
          font-size: 14px;
          // margin-right: 15px;
        }
      }

      .info {
        display: flex;
        align-items: center;
        gap: 30px;

        li {
          color: #8383a9;
          font-weight: 500;

          &:not(:last-child) {
            position: relative;

            &:before {
              content: "";
              position: absolute;
              height: 8px;
              width: 8px;
              background: #8383a9;
              top: 50%;
              border-radius: 50%;
              transform: translateY(-50%);
              right: -18px;
            }
          }
        }

        &+ul.offer img {
          height: 25px;
        }
      }
    }
  }
}

.filters {
  &.sticky-top {
    top: 1pc;
    height: 100vh;
    overflow: hidden scroll;
  }

  &-title {
    text-transform: capitalize;
    letter-spacing: 0.04em;
    font-weight: 500;
    font-size: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 1pc;
    line-height: normal;
    color: #000;
  }

  &-list {
    color: #36454f;
    max-height: 210px;
    overflow: auto;
    padding: 5px 0;

    &::-webkit-scrollbar {
      width: 5px;

      &-track {
        background: #d8d8d8;
      }

      &-thumb {
        background-color: #000;
      }
    }

    &-title {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      margin-bottom: 10px;

      label,
      input {
        cursor: pointer;
      }

      &:hover {
        input[type="checkbox"] {
          border-color: $red;

          &:before {
            opacity: 1 !important;
            transform: scale(1) !important;
            transition: all 0.1s;
          }
        }
      }
    }

    li:last-child {
      .filters-list-title {
        margin-bottom: 0;
      }
    }
  }

  &-box {
    margin-bottom: 1pc;
  }
}

input[type="checkbox"] {
  appearance: none;
  height: 15px;
  width: 15px;
  border: 1px solid #d0d0d0;
  position: relative;
  background-color: #fff;
  border-radius: 0;

  &:before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    bottom: 0;
    background-image: url("../images/icons/check.png");
    background-size: 100% 100%;
    background-color: #ffffff00;
    background-position: center top;
    background-repeat: no-repeat;
    transform: scale(0);
    transition: all 0.1s;
    opacity: 0;
  }

  &:checked {
    border-color: $red;
    background-color: #fff;

    &:before {
      opacity: 1;
      transform: scale(1);
      transition: all 0.1s;
    }
  }

  &:not(:checked) {
    &:before {
      opacity: 0;
      transform: scale(0);
      transition: all 0.1s;
    }
  }
}

.price-range {
  color: $red !important;
}

.whatsapp {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 2pc;
  filter: drop-shadow(2px 4px 6px #00000020);
  display: flex;
  align-items: center;
  gap: 25px;
  z-index: 9999999;

  .expert-talk img {
    height: 60px;
    cursor: pointer;
  }

  img {
    object-fit: contain;
    width: auto;

    &.icon {
      height: 50px;
      // &:hover + .text {
      //   visibility: visible;
      //   opacity: 1;
      //   top: -33px;
      //   transition: all 0.5s;
      // }
    }

    &.text {
      position: absolute;
      height: 29px;
      left: -23px;
      top: -33px;
      z-index: -1;
      transition: all 0.5s;
      // visibility: hidden;
      // opacity: 0;
      z-index: -1;
      transition: all 0.5s;
    }
  }
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px;
  }

  100% {
    opacity: 1;
    margin-top: 0;
  }
}

.faq {
  background-image: url("../images/woman_walking.png");
  background-repeat: no-repeat;
  background-position: 80% center;
  background-size: contain;
  padding: 80px 0 6pc;

  .gray-bg {
    padding: 3pc;
  }

  details {
    summary {
      color: #000;
      padding: 10px 1pc;
      font-size: 17px;
    }

    .content {
      padding: 0 1pc;

      p {
        line-height: 1.6;
        font-size: 15px;
        margin-bottom: 10px;
      }
    }

    &[open] .content {
      animation: sweep 0.5s ease-in-out;
    }
  }
}

.trip-info {
  background-color: #faeded;

  ul {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 20px;
    background-color: #fff;
    border-radius: 20px;
    padding: 2pc 1pc;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;

      .icon {
        font-size: 30px;
        color: $red;
      }

      h5 {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-bottom: 5px;
      }

      p {
        font-size: 18px;
        color: #000;
      }
    }
  }
}

ul.trip-includes {
  display: flex;
  flex-wrap: wrap;
  gap: 4pc;

  li {
    text-align: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .icon {
      background: #d6d3fd;
      border-radius: 20px;
      height: 70px;
      width: 70px;
      padding: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
    }

    h5 {
      margin: 1pc 0 0;
      font-size: 16px;
    }
  }
}

.custom-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  z-index: 9999;

  &:focus-visible {
    outline: 0;
  }

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000080;
    backdrop-filter: blur(1px);
    z-index: 999;
  }

  &-body {
    overflow: hidden;
  }
}

.talk-to-expert {
  background-color: #f5f3fb;
  padding: 2pc;
  border-radius: 10px;
  max-width: 600px;
  min-width: 460px;

  .form {
    label {
      color: #000;
      font-size: 14px;
    }

    .form-control {
      background: #fff;
      padding: 10px;
    }
  }

  .heading {
    color: #877ed9;
    line-height: normal !important;
    text-transform: inherit !important;

    &+p {
      color: #000;
      font-size: 16px !important;
      line-height: normal !important;
      margin-bottom: 0;
    }
  }
}

.privacy-policy {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url("../images/decor/plane2.png");
    background-repeat: no-repeat;
    // background-size: cover;
    background-position: center;
    top: 0;
    left: 0;
    opacity: 0.3;
    z-index: -1;
  }
}

.faq-page {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url("../images/woman_walking.png");
    background-repeat: no-repeat;
    // background-size: cover;
    background-position: right center;
    top: 0;
    left: 0;
    opacity: 0.3;
    z-index: -1;
  }

  details {
    background: #faeded;
    border-radius: 10px;
    margin-bottom: 1pc;
    padding: 10px;
  }

  .faq {
    background: none;
  }
}

.about-right {
  height: 520px;
}

.why-us-box {
  text-align: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .icon {
    background: #ffdddd;
    border-radius: 20px;
    height: 110px;
    width: 110px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    transition: all 1s;
  }

  h5 {
    margin: 1.5pc 0 10px;
  }

  p {
    margin-bottom: 0;
  }

  &:hover {
    .icon {
      box-shadow: 0 0 1px 7px #ff5050ad, 0 0 20px #00000020;
      transition: all 1s;
    }
  }
}

.our-team-box .image {
  height: 300px;
  border-radius: 20px;

  img {
    height: 100%;
    width: 100%;
  }
}

.blog-box {
  .image {
    height: 300px;
    border-radius: 20px;
    position: relative;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .content {
    margin: 10px 0 0;

    p {
      line-height: 20px;
      font-size: 14px;
    }
  }

  .tags {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #ffffffb8;
    backdrop-filter: blur(1px) sepia(24);
    padding: 10px 1pc;

    li {
      font-weight: 500;

      &:not(:last-child) {
        position: relative;
        padding-right: 10px;
        margin-right: 8px;

        &:before {
          content: "|";
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          font-weight: 700;
        }
      }
    }
  }
}

.newsletter {
  background-image: url("../images/slides/slide4.jpg");
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  box-shadow: inset 0 0 0 2000px #00000087;

  .form-control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 1pc;
  }
}

.testimonials-style-2 {
  &-box {
    background-color: #fff;

    .image {
      height: 455px;
      border-radius: 20px;

      img {
        height: 100%;
        width: 100%;
      }
    }

    .right {
      p {
        font-weight: 300;
      }

      .content {
        margin: 1pc 0;
      }

      .name {
        h5 {
          font-weight: 600;
          color: $red;
          margin: 0;
        }

        p {
          color: #000;
        }
      }
    }
  }

  .swiper {

    &-button-prev,
    &-button-next {
      top: auto !important;
      bottom: 0 !important;
      border-radius: 0 !important;
    }

    &-button-prev {
      left: 0;
    }

    &-button-next {
      right: auto !important;
      left: 56px !important;
    }
  }
}

.single-blog {
  .image {
    height: 50vh;
  }

  .tags {
    position: static;
  }

  .content {
    margin-top: 1pc;

    p {
      line-height: 1.8em;
      font-size: 16px;
      font-weight: 300;
    }
  }
}

ul.filter-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background: #f8f8f8;
  padding-bottom: 1px;

  .btn {
    /* box-shadow: 0 0 20px rgb(0 0 0 / 13%); */
    font-weight: 500;
    padding: 15px 2pc;
    transition: all 0.5s;
    white-space: nowrap;
    border: 1px solid #eee;
    border-width: 0 1px !important;
    border-radius: 0;
    background: #fff;

    &.active {
      background: $red;
      color: #fff;
    }

    &:hover {
      background: $red;
      color: #fff;
      transition: all 0.5s;
    }
  }
}

.trending-blog {
  .row {
    border-radius: 20px;
  }

  .content {
    padding: 3pc;
  }

  .image {
    height: 430px;
    border-radius: 0 20px 20px 0;
  }
}

.testimonials-style-3-box {
  box-shadow: 0 0 20px #00000020;
  padding: 2pc;
  border-radius: 20px;
  transition: all 0.5s;

  &:hover {
    transform: scale(0.95);
    transition: all 0.5s;
  }

  .image {
    height: 80px;
    width: 80px;
    border-radius: 50%;
  }
}

.career-apply-box {
  background-color: #ffecec;
  padding: 2pc;
  border-radius: 20px;
  transition: all 0.5s;

  &:hover {
    transform: scale(0.97);
    transition: all 0.5s;
  }

  .image {
    height: 225px;

    border-radius: 20px;
  }
}

.trip-price-detail {
  // background-color: #f5f3fb;
  background-color: #fff;
  border: 1px solid $red;
  padding: 2pc;
  border-radius: 20px;
  margin-top: -8pc;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dcd8d8;
    padding: 10px 10px;
    color: #000000bd;
    gap: 10px;

    span {
      font-weight: 600;
      color: #000;
    }
  }

  &.style-2 {
    background-color: #fff;
    border: 1px solid $red;
    padding: 0;

    .box {
      border: 1px solid #dcd8d8;
      border-radius: 5px;
      padding: 10px 1pc;
      margin: 15px 10px;
    }

    ul {
      margin: 15px 10px;
      padding: 0 !important;

      li {
        padding: 10px 1pc;

        &:last-child {
          border: 0;
        }
      }
    }

    .top {
      border-bottom: 1px solid #dcd8d8;
      text-align: center;
      padding: 10px;
    }
  }
}

.trip-desp {
  .date-picker-module {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000080;
    backdrop-filter: blur(1px);
    z-index: 999;

    .react-daterange-picker {
      &__wrapper {
        display: none;
      }

      &__calendar {
        position: static !important;
      }
    }
  }
}

.trip-dates {
  .swiper-slide {
    &:nth-child(3n + 1) .trip-dates-box {
      box-shadow: inset 0 0 0 2000px #ced2ffc4;
    }

    &:nth-child(3n + 2) .trip-dates-box {
      box-shadow: inset 0 0 0 2000px #ffe4c5c4;
    }

    &:nth-child(3n + 3) .trip-dates-box {
      box-shadow: inset 0 0 0 2000px #c5f1ffc4;
    }
  }

  &-box {
    padding: 2pc;
    border-radius: 20px;
    background-color: #fff;
    background-image: url("../images/decor/paper.png");
    background-size: contain;
    box-shadow: 0 0 20px #00000011;

    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .price {
      margin-bottom: 0;
    }

    ul.trip-timeline {
      margin: 1pc 0;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        height: 100%;
        border-left: 2px solid #ff5355;
        left: 7.5px;
        top: 0;
        z-index: 0;
      }

      li {
        display: flex;
        align-items: flex-start;
        gap: 17px;
        color: #000;
        margin-bottom: 1pc;

        h6 {
          margin: 0;
          color: #ff5355;
          font-size: 18px;
        }

        .icon {
          background: #ff5355;
          height: 15px;
          width: 15px;
          border-radius: 50%;
        }
      }
    }

    .flex-timeline {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #eee;
      border-width: 1px 0 !important;
      margin-bottom: 1pc;
    }
  }
}

.trip-all-detail {
  .itinerary-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

ul.trip-itinerary li {
  margin-bottom: 2pc;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    h6 {
      margin-bottom: 0;
    }

    &.active .icon {
      transform: scaleY(-1);
      transition: all 0.5s;
    }

    .icon {
      transition: all 0.5s;
      color: #000;
      font-size: 22px;
    }
  }

  p {
    font-size: 15px;
    font-weight: 300;
  }

  span {
    color: #ff5050;
    background: #faeded;
    padding: 1px 14px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 500;
  }
}

.trip-package {
  &-box {
    padding: 2pc;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 0 20px #00000021;
    text-align: center;

    // background-image: url("../images/decor/pattern2.png");
    // background-attachment: fixed;
    // background-size: cover;
    // box-shadow: inset 0 0 0 2000px #c0e1eec2;
    h4.price {
      margin-bottom: 1pc;
      text-decoration: line-through;
      color: gray;
    }

    h2.price {
      font-size: 26px;
      margin: 0;
    }

    h6 {
      border-bottom: 1px solid #ddd;
      padding-bottom: 1pc;
      margin: 1pc 0;
    }
  }
}

.trip-pickup-drop ul {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1pc;
  gap: 28px;

  h5 {
    margin: 0;
  }

  .line {
    width: 40px;
    height: 2px;
    background: #232323;
  }

  .icon {
    font-size: 35px;
  }
}

.trip-overview {
  p {
    margin: 0;
  }

  h5 {
    margin: 1pc 0;
  }

  ul {
    margin: 1pc 0;
  }

  ul.quick-info li {
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      color: #000;
      font-weight: 500;
    }
  }
}

ul.not-to-miss {
  margin: 1pc 0;

  li {
    display: flex;
    gap: 10px;

    .icon {
      color: $red;
    }
  }
}

.inclusion-exclusion {
  ul:not(:last-child) {
    margin-bottom: 2pc;
  }

  li {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    color: #000;
    margin-bottom: 10px;

    .icon {
      font-size: 22px;
    }
  }
}

.checkout-box {
  background-color: #fff;
  box-shadow: 0 0 20px #00000020;
  border-radius: 10px;
  padding: 2pc;
}

.sign-in {
  height: 100vh;
  overflow: hidden;
  background-color: #f6f6f6;

  .right {
    height: calc(100% - (2pc));
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    // align-items: center;
    background-color: #fff;
    margin: 1pc;
    border-radius: 20px;
    padding: 2pc 6pc;
    overflow: hidden;
  }

  .left {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    padding-bottom: 12pc;

    img.left-image {
      width: 100%;
      object-fit: contain;
      height: 500px;
    }

    .boxes {
      position: absolute;
      bottom: 1pc;
      left: 0;
      width: 100%;
    }
  }

  &-with-timeline {
    .form [class*="col-"] {
      margin-bottom: 40px;
    }
  }
}

.home-trip-tabs {
  position: fixed;
  bottom: 0;
  z-index: 200;
  background: #fff;
  padding: 16px 0;
  width: 100%;
  border-radius: 16px 16px 0 0;
  box-shadow: 0px -8px 20px #00000024;

  .inner {
    border-radius: 10px;
  }

  .tabs {
    gap: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    button {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 4px;
      font-size: 12px;
      line-height: normal;
      padding: 0;
      font-weight: 500;
      font-size: 13px;
      color: #000;
      span {
        display: none;
      }
      &.active {
        color: $red;

        .img {
          background: $red;
        }
      }

      .img {
        height: 40px;
        width: 40px;
        padding: 10px;
        margin: 0;
        background: $red;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }

      img {
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }
  }

  .show-tab {
    margin: 10px !important;
  }

  .show-tab {
    margin: 1pc 0;

    &.form-control {
      max-width: -webkit-fill-available;
    }
  }

  .form {
    label {
      color: #000;
    }

    .form-control,
    .form-select {
      padding: 5px 10px;
      font-size: 14px;
    }
  }

  .traveller {
    &.room-guest {
      height: 240px;
      overflow: hidden scroll;
    }

    p.text {
      text-align: left;
      font-size: 12px;
      font-weight: 500;
      margin: 0;
    }
  }

  .date-picker-module {
    .react-daterange-picker {
      width: 100%;

      &__wrapper {
        border: 1px solid #ebebeb;
        padding: 3px 10px;
        border-radius: 5px;
        background-color: #f8f8f8;
        width: 100%;
      }

      &__inputGroup:not(:first-child) {
        text-align: right;
      }
    }
  }
}

.flights {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;

  .box-head {
    border-bottom: 1px solid $red;
    padding: 10px 1pc;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-box {
    background-color: #fff;
    box-shadow: 0 0 20px #00000020;
    border-radius: 10px;
    padding: 1pc;
  }

  &-head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    color: #36454f;

    label {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 500;
    }
  }

  &-left {
    width: 22%;
    top: 1pc;
    height: 100vh;

    .flights-box {
      border: 1px solid $red;
      box-shadow: none;
      padding: 0;
    }

    .box {
      margin-bottom: 1pc;
      padding: 1pc;
      overflow-y: auto;
      max-height: 90vh;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .title {
      text-transform: capitalize;
      letter-spacing: 0.04em;
      font-weight: 500;
      padding-bottom: 10px;
      border-bottom: 1px solid #e6e6e6;
      margin-bottom: 1pc;
      line-height: normal;
      color: #000;
    }

    .trip-price-detail {
      background-color: #ffffff;
      border: 1px solid #e6e6e6;
    }

    .selected-flight {
      // background-color: #f5f3fb;
      padding: 1pc;
      border-radius: 10px;
      background-color: #ffffff;
      border: 1px solid #e6e6e6;

      &>p.text-center {
        border-bottom: 2px solid #ff5355;
        width: max-content;
        margin: 0 auto 1pc;
      }

      .image {
        width: 50px;
        height: 50px;
        border-radius: 5px;
        margin: 0 auto 10px;

        img {
          height: 100%;
          width: 100%;
        }
      }

      ul.d-flex {
        justify-content: space-evenly;
      }
    }
  }

  &-inner {
    display: flex;
    gap: 15px;
    align-items: center;
    margin: 1pc 0;

    .left .image {
      height: 100px;
      width: 100px;
      border-radius: 10px;
    }

    .right {
      justify-content: space-around;
      display: flex;
      gap: 15px;
    }
  }
}

.account-banner {
  background-image: url("../images/profile_page.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 20px;
  height: 50vh;
  /* box-shadow: inset 0 0 0 2000px #00000060; */
  display: flex;
  align-items: flex-end;
  position: relative;

  .container-fluid {
    position: relative;
    z-index: 5;
  }

  // &:before {
  //   content: "";
  //   position: absolute;
  //   height: 100%;
  //   width: 100%;
  //   top: 0;
  //   left: 0;
  //   background-image: linear-gradient(180deg, #00000000 72%, #090909a6 100%);
  //   border-radius: 20px;
  // }

  ul.tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1px;
    overflow: hidden;
    position: relative;
    padding: 0 40px;

    li {
      background: #fff;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px 2pc;
      position: relative;
      font-weight: 500;
      color: #000;
      cursor: pointer;
      white-space: nowrap;
      border-right: 1px solid #cdcdcd;
      transform: skewX(-30deg);

      &:first-child {
        border-top-left-radius: 5px;
      }

      &:last-child {
        border-top-right-radius: 5px;
      }

      p,
      .icon {
        transform: skewX(30deg);
      }

      &.active {
        background-color: $red;
        color: $white;

        .icon {
          color: $white !important;
        }
      }

      .icon {
        font-size: 18px;
      }
    }
  }
}

.account-profile {
  padding-top: 4pc;

  .user-name {
    margin-top: -80px;
    background: #fff;
    width: max-content;

    .image {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      border: 3px solid #fff;
    }
  }

  .trip-price-detail {
    top: 6pc;
  }

  .iternary-main {
    // display: flex;
    // align-items: center;
    // gap: 20px;
    background: #fff;
    box-shadow: 0 0 20px #00000020;
    border-radius: 15px;
    padding: 1pc;

    .content {
      padding: 0 1pc;

      p {
        line-height: normal;
      }
    }

    &.small-box {
      .content {
        h4 {
          font-size: 20px;
        }
      }
    }

    .img {
      width: 100%;
      max-height: 400px;
      height: 100%;
      border-radius: 15px;

      img {
        border-radius: inherit;
      }
    }
  }
}

.account-booking {
  .col-12:nth-child(even) .packages-box {
    background-color: #ffe4c566;
  }

  .col-12:nth-child(odd) .packages-box {
    background-color: #ced2ff66;
  }

  .packages-box {
    transition: all 0.5s;
    box-shadow: none;

    &:hover {
      transform: scale(0.99);
      transition: all 0.5s;
    }
  }
}

.search-flight-form {
  background-image: url("../images/slides/slide4.jpg");
  background-position: center;
  background-attachment: fixed;
  padding: 3pc 0;

  &-inner {
    padding: 2pc;
    background-color: #ffffffd9;
    border: 1px solid #fff;
    border-radius: 20px;

    .date-picker-module {
      .react-daterange-picker {
        width: 100%;

        &__wrapper {
          border: 1px solid #ebebeb;
          padding: 10px 10px;
          border-radius: 5px;
          background-color: #ffffff;
          width: 100%;
        }

        &__inputGroup:not(:first-child) {
          text-align: right;
        }
      }
    }

    .form {
      align-items: center;

      .col-auto {
        width: 20%;
      }

      &-control {
        background-color: #fff !important;
      }

      .traveller-absolute {
        position: absolute;
        width: 100%;
        bottom: -85%;
        padding: 10px;
        background-color: #fff !important;
      }

      label {
        color: #000;
      }

      .select-traveller {
        &__value-container {
          padding: 10px 10px;
        }

        &__input-container {
          margin: 0;
          padding: 0;
        }

        &__indicator {
          padding-top: 0;
          padding-bottom: 0;
        }

        &__control {
          border: 0;
          border-radius: 5px;
        }
      }
    }
  }
}

.deyor-coins {
  .price .text-warning {
    text-shadow: 2px 1px 1px #000000a8;
  }
}

.get-visa {
  background-image: url("../images/visa-bg.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 0 2000px #0000006b;

  .sort-by {
    &__value-container {
      padding: 7px 10px;
    }

    &__control {
      border-radius: 5px;
    }
  }

  .visa-box {
    text-align: center;
    background-color: #fff;
    padding: 2pc;
    border-radius: 10px;
    box-shadow: 0 0 20px #00000020;
    transition: all 0.5s;

    &:hover {
      transform: scale(0.98);
      transition: all 0.5s;
    }

    .icon {
      margin-bottom: 2pc;
    }
  }
}

.visa-procedure {
  width: 748px;

  ul.tabs {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #cad0d0;

    li {
      color: #000;
      padding: 10px 2pc;
      border-right: 1px solid #cad0d0;
      font-size: 14px;
      cursor: pointer;

      &.active {
        color: #fff;
        background: $red;
      }
    }
  }

  .content {
    padding: 1pc 2pc;
    height: 40vh;
    overflow: hidden scroll;

    &::-webkit-scrollbar {
      width: 5px;

      &-track {
        background: #d8d8d8;
      }

      &-thumb {
        background-color: #000;
      }
    }

    li,
    p {
      line-height: normal;
      font-size: 15px;
    }

    h6 {
      margin: 1pc 0 10px;
    }

    p {
      margin: 10px 0;
    }
  }
}

.get-traveller-data {
  background-color: #fff;
  padding: 1pc;
  width: 681px !important;
  border-radius: 6px;
  position: absolute;
  right: 0;
  z-index: 99;
  box-shadow: 0 0 20px #00000010;
  margin-top: 10px;

  &.room-guest {
    height: 240px;
    overflow: hidden scroll;
  }

  p.text {
    text-align: left;
    font-size: 13px;
    font-weight: 600;
  }

  ul.number {
    display: flex;
    box-shadow: 0 0 10px #00000020;
    border-radius: 5px;
    width: max-content;

    li {
      padding: 2px 12px;
      font-weight: 500;
      font-size: 13px;
      cursor: pointer;

      &.active {
        background: $red;
        color: #fff;
        border-radius: 5px;
      }
    }
  }
}

.room-category-slider {
  width: 100%;

  img {
    height: 300px;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}

.hotel-despcription {
  .filter-tabs {
    background-color: #fff;
    gap: 20px;
    justify-content: start;

    .btn {
      display: flex;
      align-items: center;
      gap: 10px;
      background: #f4f2f2;
      border: 1px solid #c7c7c7 !important;
      border-radius: 5px;
      padding: 10px 3pc;

      &.active {
        border-color: $red !important;
      }
    }
  }

  .service-btn.btn {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 2px dotted #c7c7c7;
    padding: 10px 1pc;
    color: #797878;

    img {
      height: 20px;
      width: auto;
      object-fit: contain;
    }
  }
}

.hotel-slider {
  margin: 0 1pc 1pc;

  &-box {
    height: 80vh;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
}

.itinerary-top-banner {
  background-image: url("../images/slides/slide4.jpg");
  background-attachment: fixed;
  background-size: cover;

  .content {
    text-align: center;
    background: #ffffffc2;
    padding: 2pc;
    margin: 0 auto;
    border-radius: 16px;
    border: 1px solid #fff;

    h1 {
      margin-bottom: 1.5pc;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      li {
        padding: 5px 10px;
        border-radius: 5px;
        font-weight: 600;
      }
    }
  }
}

.why-deyor-box {
  background-color: #ffebff;
  padding: 2pc;

  li {
    color: #000;
  }
}

ul.filter-1 {
  display: flex;
  gap: 20px;
  // justify-content: space-evenly;
  align-items: center;
  font-weight: 700;
  // background: #f6f6f6;
  padding: 25px 0;
  color: #888888;
  margin-bottom: 0 !important;

  li {
    padding: 0 10px;
    cursor: pointer;

    &.active {
      border-bottom: 2px solid #000;
      color: #000;
    }
  }
}

ul.filter-2 {
  display: flex;
  gap: 20px;
  // justify-content: space-evenly;
  align-items: center;
  padding: 20px 0;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;

  li {
    font-weight: 700;
    color: gray;
    cursor: pointer;
    background-color: white;
    // box-shadow: 0 0 20px #00000020;
    box-shadow: inset 0px 2px 10px rgba(0, 0, 0, 0.06);
border-radius: 22px;
    padding: 8px 2pc;
    // border-radius: 7px;
    display: flex;
    align-items: center;
    gap: 7px;

    .icon {
      font-size: 18px;
    }

    &.active {
      // background-color: ;
      color: $red;
    }
  }
}

.edit-option {
  display: flex;
  align-items: center;
  gap: 7px;

  button {
    border: 0;
    background-color: transparent;
    font-weight: 500;
  }
}

.flight-info {
  .box {
    box-shadow: 0 0 20px #00000010;
    border-radius: 10px;
    background-color: #ffffff;
    padding: 0px 15px;

    .image {
      overflow: hidden;
      width: auto;
      height: 70px;
      position: relative;
      border-radius: 3px;

      img {
        height: 100%;
        width: 100%;
      }

      .absolute {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: #ffffffb0;
        color: red;
        font-weight: 500;
        text-align: center;
      }
    }

    .content {
      padding: 2pc 15px;
     

      ul {
        display: flex;
        align-items: center;
        gap: 1pc;
        justify-content: space-evenly;

        img.plane-img {
          height: auto;
          object-fit: contain;
          width: 300px;
        }

        p {
          margin: 0;
          line-height: normal;
        }

        li:last-child {
          border-left: 1px solid #797979;
          padding: 1pc 0 1pc 1pc;
        }

        &:not(:last-child) {
          border-bottom: 1px solid #eee;
          padding-bottom: 14px;
          margin-bottom: 14px;
        }
      }
    }
    .borderbtom{
      border-bottom: 1px solid #e7e7e7;
    }
  }
}

.hotel-img-slider ,.flight-info{
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 2pc;
    border-bottom: 1px solid #eee;
    padding-bottom: 1pc;
    margin-bottom: 3pc;
    h4{
      margin: 0;
    }
  }
}

ul.initinery-detail-box {
  padding: 0 2pc;

  &>li {
    display: flex;
    align-items: flex-start;
    gap: 30px;
    padding-bottom: 1pc;
    position: relative;

    &:not(:last-child):before {
      content: "";
      position: absolute;
      height: 100%;
      width: 1px;
      background: #d9d9d9;
      left: 8pc;
      top: 0;
    }

    &.active {
      .dot {
        background: #ffc107;
      }
    }
  }

  p.day {
    color: #8b85c2;
    font-weight: 600;
    margin: 0;
  }

  p.date {
    color: #8b85c2;
  }

  .dot {
    height: 22px;
    width: 22px;
    background: #d9d9d9;
    border-radius: 50%;
    position: relative;
  }

  .name-hotel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1pc;

    button.btn {
      background: #f5f3fb !important;
      color: #8b85c2 !important;
    }
  }

  .box {
    background: #ffff;
    box-shadow: 0 0 20px #00000010;
    padding: 1pc 2pc;
    border-radius: 12px;
  }

  .activity {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    h5 {
      margin: 0;
    }
  }

  .inclusion-exclusion {
    display: flex;
    gap: 7pc;

    li {
      font-size: 13px;
      color: #797979;
      margin: 0;

      .icon {
        font-size: 17px;
      }
    }
  }
}

.itinerary-flight {
  ul.flight-filter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 10px;
    margin-bottom: 1pc;

    .sort-by__value-container {
      width: auto;
    }
  }

  .select-date-origin {
    align-items: center;

    label {
      color: #000;
    }

    .date-picker-module {
      .react-daterange-picker {
        width: 100%;

        &__wrapper {
          border: 1px solid #efeded;
          padding: 10px 10px;
          border-radius: 5px;
          background-color: #f8f8f8;
          width: 100%;
        }

        &__inputGroup:not(:first-child) {
          text-align: right;
        }
      }
    }
  }
}

.departing-flights {
  .heading {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5pc;
  }

  .box {
    background-color: #fff;
    box-shadow: 0 0 20px #00000021;
    border-radius: 10px;
    padding: 1pc;
    margin: 1pc 0;

    .inner-box {
      display: flex;
      gap: 15px;
      align-items: center;

      .down-arrow {
        font-size: 40px;
        transition: all 0.5s;
        color: #000;
      }

      &.active .down-arrow {
        color: #ffc107;
        transform: scaleY(-1);
        transition: all 0.5s;
      }
    }

    .left .image {
      height: 100px;
      width: 100px;
      border-radius: 10px;
    }

    .right {
      justify-content: space-around;
      display: flex;
      row-gap: 15px;
      column-gap: 15px;
      align-items: center;
      flex-wrap: wrap;
    }

    .outer-box {
      border-top: 1px solid #ddd;
      margin-top: 1pc;
      padding: 1pc 2pc 0;

      ul li {
        display: flex;
        gap: 15px;
        position: relative;
        justify-content: flex-start;

        .time {
          width: 40px;
        }

        &:not(:last-child):before {
          content: "";
          position: absolute;
          left: 3.7pc;
          top: 0;
          width: 1px;
          height: 100%;
          background: #ffc107;
        }

        .dot {
          height: 10px;
          width: 10px;
          background: #ffc107;
          border-radius: 50%;
        }
      }

      p.travel-time {
        border: 1px solid #ddd;
        border-width: 1px 0;
        padding: 10px 0;
        margin: 10px 0;
      }
    }

    .transfer-outer {
      P {
        margin: 0;
      }

      ul.location {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #eee;
        border-width: 1px 0;
        padding: 10px 0;

        li {
          display: flex;
          align-items: center;
          gap: 10px;

          h6,
          h5 {
            margin: 0;
          }
        }
      }

      ul.price {
        border: 1px solid #eee;
        margin: 1pc 0;

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 1pc;
          line-height: normal;

          span {
            color: #000;
            font-weight: 500;
          }

          &:not(:last-child) {
            border-bottom: 1px solid #eee;
          }
        }
      }
    }
  }
}

.itinery-activity .box {
  position: relative;
  border-radius: 15px;

  &:hover img {
    transform: scale(1.3);
  }

  .image {
    height: 300px;
    border-radius: inherit;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .content {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 1pc;
    background: linear-gradient(180deg, #00000000 57%, #090909cc 100%);
    border-radius: inherit;

    button.red-bg {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 40px;
      height: 30px;
      width: 30px;
      color: #fff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 10px;
    }

    h5,
    p {
      color: #fff;
    }

    h5 {
      margin-bottom: 0;
    }

    .css-1c99szj-MuiRating-icon {
      color: #fff;
    }
  }
}

.career-form {
  .form label {
    color: #000;
  }
}

.form {
  .sort-by {
    &__value-container {
      width: auto;
      padding: 5px 1pc;
    }

    &__control {
      height: auto;
      border-color: #efeded !important;
      background: #ffffff;
      border-radius: 5px;
    }
  }
}

.raise-ticket-modal {
  p {
    margin-bottom: 0;
    color: #000;
  }
}
.pdfbtn{
  color: #888888;
  font-weight: 700;
}
.modalcustom{
  p.price{
    margin-bottom: 20px;
  }
  .packages-inner-right{
    padding: 3pc 2pc;
  }
  .packages-inner-left img{
    height: 285px;
  }
  h4{
    font-size: 22px;
  }
  .modal-header{
    padding: 0;
    border: none;
  }
  .btn-close {
    position: absolute;
    top: 7px;
    right: 13px;
    z-index: 8856;
  }
  ul.offer img{
    height: 20px;
  }
}
.gallerycardbox{
  .modal-header{
    border-bottom: none;
  }
}
.imgradius img{
  border-radius: 10px;
}
.react-calendar__tile--now {
  background: #d31a1a !important;
  color: #fff !important;
}