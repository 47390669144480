@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');

$lexend: 'Lexend', sans-serif;
$source:'Source Sans Pro', sans-serif;
$black: #000;
$white: #fff;
$white-50: #ffffff80;
$gray: #888888;
$red: #FF5050;
$green: #068A4F;

