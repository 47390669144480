html {
  scroll-behavior: smooth;
}

.outlineButton {
  outline: none;
  border: none;
  background-color: white;
  border-radius: 10px;
  padding: 10px 25px;
  border: solid 1px rgba(123, 123, 123, 1);
  color: rgba(123, 123, 123, 1);
}

// ---------------------------------------------------------
.active_flight {
  background-color: #fff;
  padding: 50px 0;
}

.filght_card ul {
  display: inline-flex;
  gap: 10px;
}

.filght_card li a {
  border: 1px solid #d9d9d9;
  padding: 12px 17px;
  border-radius: 100px;
  color: #888888;
}

.filght_card li a.active {
  box-shadow: inset 0px 2px 10px rgba(0, 0, 0, 0.06);
  border-radius: 22px;
  padding: 10px 12px;
  border-radius: 100px;
  color: #ff5a5a;
  font-weight: 700;
}

.active_flight .btn-book {
  background-color: #ff5a5a;
  padding: 10px 15px;
  border-radius: 5px;
  color: #fff;
  font-weight: 700;
}

.table_headermain img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.table_headermain {
  background-color: #e9e9e9;
  padding: 14px 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  align-items: center;
}

.table_headermain p {
  margin-left: 16px;
}

.table_tpading p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
}

.table_tpading h6 {
  font-weight: 400;
  font-size: 14px;
  color: #888888;
}

.table_tpading tbody td {
  color: #888888;
  font-size: 15px;
  font-weight: 400;
}

.filter_card_area {
  border: 0.5px solid #d9d9d9;
  padding: 30px 23px;
  border-radius: 22px;
  position: relative;
  margin-bottom: 10px;
  margin-right: 20px;

  background-color: #fff;
}

.left_min-card img {
  width: 50px;
  height: 50px;
  border-radius: 10%;
}

.min-headr {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.left_min-card {
  display: flex;
  align-items: end;
}

.left_min-card p {
  font-size: 19px;
  color: #3d3d3d;
  margin-left: 9px;
  line-height: 1;
}

.right_min-card h4 {
  color: #ff5050;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
}

.min_body_filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.min_body_filter p {
  margin-bottom: 0;
}

.rocket_img img {
  width: 60%;
}

.c_bodyfilter {
  text-align: center;
}

.l_bodyfilter .time_filter,
.c_bodyfilter .hourse,
.r_bodyfilter .time_filter {
  color: #000;
  font-size: 15px;
  font-weight: 500;
}

.docureiton_filter,
.r_bodyfilter .docureiton_filter {
  color: #3d3d3d;
  font-size: 14px;
  font-weight: 500;
}

.c_bodyfilter .non_stoop {
  color: #c4cdd5;
  font-size: 13px;
  font-weight: 500;
}

.icon_absoli {
  position: absolute;
  top: 10px;
  right: 15px;
}

.heigh_filter_section {
  height: 90vh;
  overflow: auto;
}

.heigh_filter_section::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.heigh_filter_section::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #faeded;
}

.heigh_filter_section::-webkit-scrollbar-thumb {
  background: #ff5050;
  border-radius: 10px;
}

.border_categoru {
  border: 0.5px solid #d9d9d9;
  padding: 30px 30px;
  border-radius: 10px;
  background-color: #fff;
}
.newceckout_top {
  background-image: url("../images/travelling_chekcout.png");
  padding: 60px 35px;
  background-repeat: no-repeat;
  background-position: center;
  height: 535px;
  background-size: cover;
  display: flex;
  align-items: center;
}
.bg_trapsprent {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 30px;

  min-height: 299px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.bg_trapsprent h3 {
  font-size: 3pc;
  color: #fff;
  font-family: "Lexend";
  font-weight: 400;
}
.bg_trapsprent p {
  color: #fff;
  font-size: 23px;
  font-weight: 200;
}
.flight_details {
  padding: 41px 0;
}
.details_ldt h5 {
  color: #ff5050;
  font-size: 23px;
  font-weight: 600;
  position: relative;
}
.details_ldt h5::after {
  position: absolute;
  bottom: -6px;
  background-color: #ff5050;
  width: 200px;
  content: "";
  left: 0;
  right: 0;
  margin: auto;
  height: 2px;
}
.card_fligjaplly {
  background: #ffffff;
  box-shadow: 0px 2.28988px 11.4494px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  padding: 40px;
}
.list_updonw ul {
  display: inline-flex;
  gap: 10px;
}
.list_updonw li {
  color: #000;
  font-weight: 500;
  font-size: 20px;
}
.right_pp p {
  color: #ff5050;
  font-size: 17px;
  font-weight: 500;
}
.list_updonw .lightfont li:first-child {
  font-size: 12px;
  font-weight: 400;
}

.list_updonw .lightfont li:last-child {
  font-weight: 300;
  font-size: 12px;
}
.left_ari h4 {
  font-size: 14px;
  font-weight: 400;
}
.left_ari h4 img {
  width: 3%;
}
.right_sale h3 {
  font-size: 14px;
  font-weight: 400;
}
.right_sale h3 span {
  color: #ff5050;
}
.bgtable_chekcout {
  background-color: #f0f0f0;
  padding: 20px 23px;
  border-radius: 4px;
}
.bgtable_chekcout .table {
  margin-bottom: 0;
}
.summery_scio h4 {
  font-size: 20px;
  margin-bottom: 0;
}
.borderfare_summery {
  border: 0.5px solid #d9d9d9;
  border-radius: 10px;
  padding: 18px;
}
.borderfare_summery h3 {
  font-size: 20px;
  margin-bottom: 0;
  padding: 15px 0;
}
.brder_round {
  border: 0.5px solid #d9d9d9;
  border-radius: 10px;
  padding: 18px 17px;
}
.brder_round h5 {
  color: #888888;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 15px;
}

.taotal_amount h5 {
  margin-top: 10px;
  color: #4d4d4d;
}
.import_lnfo_list h3 {
  font-size: 23px;
  margin-bottom: 19px;
}
.import_lnfo_list ul {
  padding-left: 15px;
}
.import_lnfo_list li {
  list-style: disc;
  margin-bottom: 5px;
}
.bg_entername {
  background-color: #ffedd3;
  margin-bottom: 0;
  padding: 5px 0;
}
.input_text_contgrol .form-control {
  height: 60px;
}
.input_text_contgrol .from-group {
  position: relative;
}
.input_text_contgrol .from-group label {
  position: absolute;
  top: -14px;
  left: 10px;
  background-color: #fff;
  padding: 0px 16px;
  z-index: 99;
}
.input_text_contgrol .form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.css-319lph-ValueContainer {
  height: 58px;
}
.payment_info ul {
  padding: 0;
  margin: 0;
  display: inline-flex;
  gap: 49px;
  text-align: center;
}
.payment_info ul li img {
  width: 22%;
  margin-right: 10px;
}

.payment_info ul li.active {
  border-bottom: 2px solid #ff5050;
  color: #ff5050;
}
// .payment_textinput{
//   position: absolute;
//   top: -14px;
//   left: 10px;
//   background-color: #fff;
//   padding: 0px 16px;
//   z-index: 99;
// }

.payment_textinput .form-control {
  height: 60px;
}
.payment_textinput .from-group {
  position: relative;
}
.payment_textinput .from-group label {
  position: absolute;
  top: -14px;
  left: 10px;
  background-color: #fff;
  padding: 0px 16px;
  z-index: 99;
}
.payment_textinput .form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}
.secure_p p {
  color: #212b36;
  font-weight: 500;
}

.btn.btn-paynew {
  background-color: #ff5050 !important;
  color: #fff !important;
  padding: 7px 21px;
}

.tickets_point h3 {
  color: #919eab;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 0;
  padding: 15px 0;
}
.tickets_point p {
  color: #212b36;
  font-weight: 400;
  font-size: 18.3191px;
}

// ---------------------------------------------------------
.outlineButton:hover {
  border: solid 1px rgba(255, 80, 80, 1);
  color: rgba(255, 80, 80, 1);
}

body {
  font-family: $lexend;
  color: #797979;
  line-height: 1.7em;
  font-weight: 400;
  background: $white;
  overflow-x: hidden;
}

main {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.ptb-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.px-4pc {
  padding-left: 4pc;
  padding-right: 4pc;
}

.mx-4pc {
  margin-left: 4pc;
  margin-right: 4pc;
}

.bg-fix {
  background-attachment: fixed;
}

.btn {
  border-radius: 0.375rem;

  &:focus {
    box-shadow: none;
  }

  &-custom {
    font-weight: 500;
    --bs-btn-padding-x: 1pc;
    // letter-spacing: 1px;
  }

  &.with-icon {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &.red-bg {
    color: #fff;

    &:first-child:active {
      color: $white !important;
      border-color: $red;
    }

    &:active {
      color: $white !important;
      border-color: $red;
    }
  }
}

.lexend {
  font-family: $lexend !important;
}

.red {
  color: $red !important;
}

.red-bg {
  background-color: $red !important;
}

.green {
  color: $green !important;
}

.green-bg {
  background-color: $green !important;
}

.gray-bg {
  background-color: #f3f4f8 !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.list-circle {
  li {
    list-style-type: circle;
    list-style-position: inside;
  }
}

.flex-1 {
  flex: 1;
}

button:focus-visible {
  outline: 0;
}

.title-section {
  .heading {
    line-height: 50px;
    text-transform: capitalize;
    font-weight: 400;

    & + p {
      font-size: 20px;
      line-height: 40px;
    }

    &.bottom-line {
      position: relative;
      padding-bottom: 1pc;

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        width: 50px;
        background-color: $red;
      }
    }
  }

  .sub {
    width: max-content;
    position: relative;
    letter-spacing: 5px;
  }

  &.bg {
    position: relative;
    width: max-content;
    padding: 1pc 0 1pc;

    &:before {
      background-image: url("../images/decor/pattern4.png");
      background-repeat: no-repeat;
      background-position: center;
      content: "";
      position: absolute;
      height: 100%;
      width: 60%;
      top: 0;
      left: -1pc;
      z-index: 0;
      opacity: 0.5;
    }

    .heading {
      line-height: normal;
      margin-bottom: 0;
    }

    .heading,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a {
      position: relative;
      z-index: 4;
    }
  }

  .bg-2 {
    position: relative;
    width: max-content;
    padding: 0 3pc 3pc;

    &:before {
      background-image: url("../images/decor/landing-plane-img.png");
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: contain;
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      bottom: 0;
      right: -2pc;
      z-index: 0;
    }
  }

  h6 {
    font-size: 20px;
    line-height: 28px;
    text-transform: capitalize;
    margin: 0;
  }
}

details:focus-visible {
  outline: 0;
}

summary:focus-visible {
  outline: 0;
}

p {
  line-height: 1.8em;

  &:last-child {
    margin-bottom: 0;
  }
}

.img-cover {
  object-fit: cover;
}

.img-contain {
  object-fit: contain;
}

.img-position-top {
  object-position: top;
}

.img-fill {
  object-fit: fill;
}

a {
  text-decoration: none;
  color: #000;
  transition: all 0.5s;

  &:hover {
    transition: all 0.5s;
    color: $red;
  }
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }

  &:not(.row) li:last-child {
    margin: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black;
}

.btn-sm {
  font-size: 0.875rem;
  // padding: 7px 22px;
  border-radius: 0.2rem;
}

.main-logo {
  height: 50px;
  width: auto;
  object-fit: contain;
  transition: all 0.5s;
}

.translateX {
  transform: translateX(0) !important;
}

.pointer {
  cursor: pointer;
}

.overlay {
  position: relative;

  &:before {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    content: "";
    z-index: 66;
    pointer-events: none;
  }
}

#scrollUp {
  position: fixed;
  right: 1pc;
  bottom: 1pc;
  opacity: 0;

  .scroll-inner {
    height: 45px;
    width: 45px;
    background: #f16331;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #fff;
    font-size: 22px;
    cursor: pointer;
  }
}

.image {
  overflow: hidden;

  img {
    border-radius: inherit;
    transition: all 0.5s ease-in-out;
  }

  &:not(.not-hover) img:hover {
    transform: scale(1.2);
    transition: all 0.5s ease-in-out;
  }
}

#call-us {
  position: fixed;
  right: 2pc;
  bottom: 1pc;
  filter: drop-shadow(0px 0px 6px gray);
  z-index: 9999;
  opacity: 0;

  &:before {
    content: "";
    position: absolute;
    border: 2px solid #fff;
    bottom: 0;
    right: 0;
    transform: translate(-2px, -2px);
    height: 15px;
    width: 15px;
    background: #128c7e;
    z-index: 0;
  }

  .call-us-inner {
    height: 55px;
    width: 55px;
    background: #128c7e;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #fff;
    font-size: 30px;
    transform: scaleX(-1);

    i {
      animation: phone 1.5s linear 0s infinite alternate;
    }
  }
}

img {
  object-fit: cover;
}

// #preloader {
//   position: fixed;
//   height: 100vh;
//   width: 100vw;
//   z-index: 99;
//   top: 0;
//   left: 0;
//   background: #f8f9fa;
//   .preloader-inner {
//     background-image: url("../images/loader.gif");
//     background-size: 16pc;
//     background-repeat: no-repeat;
//     background-position: center;
//     height: 100%;
//     width: 100%;
//   }
// }
.line {
  width: 70px;
  background: #fff;
  height: 1px;
}

input:focus-visible {
  outline: 0;
}

.form {
  &.filled-input {
    .form-control,
    .select2-selection--single {
      background-color: #f3f4f8ab !important;
    }
  }

  [class*="col-"] {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .form-check {
    &-label {
      flex: 1;
      color: #000;
      display: flex;
      align-items: center;
      gap: 11px;
      cursor: pointer;
    }

    &:not(.form-check-inline) {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    &.form-check-inline {
      .form-check-input {
        margin-right: 10px;
      }
    }

    input[type="checkbox"] {
      height: 20px;
      width: 20px;
      border: 1px solid #d0d0d0;
      border-radius: 0;
      position: relative;
      background-color: #fff;
      cursor: pointer;

      &.form-check-input:focus {
        border-color: #00000040;
        box-shadow: none;
      }

      &:checked {
        background-color: $green;
        border-color: $green;
      }
    }

    input[type="radio"] {
      cursor: pointer;

      &:checked {
        background-color: $red;
        border-color: $red;
      }

      &:focus {
        border-color: $red;
        outline: 0;
        box-shadow: 0 0 0 0.25rem #ff505040;
      }
    }
  }

  .select2-container--default {
    width: 100% !important;

    .select2-selection--single {
      height: 40px !important;
      border-radius: 0;
      width: 100%;
      padding: 0.375rem 0.75rem;
      background-color: #fff;
      border: 1px solid #ced4da !important;

      .select2-selection__arrow {
        height: 40px !important;
      }
    }
  }

  .form-control,
  .form-select {
    border-color: #efeded;
    padding: 12px 10px;
    background: #f8f8f8;

    &:focus {
      box-shadow: none;
      border-color: $red;
      background-color: #ffefef;
      transition: all 0.5s;

      &::placeholder {
        opacity: 0;
        transition: all 0.5s;
      }
    }

    &::placeholder {
      opacity: 1;
      transition: all 0.5s;
      text-transform: capitalize;
    }
  }

  label {
    margin: 0;
    color: #838383;
    line-height: 20px;
    width: 100%;
    font-size: 15px;

    &.small {
      font-size: 0.875em;
    }
  }
}

.line-height-normal {
  line-height: normal;
}

.swiper {
  // height: 100%;
  &-button-prev,
  &-button-next {
    background: #fff;
    height: 55px !important;
    width: 55px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 50%;
    transition: all 0.5s;

    &:hover {
      background-color: $red;
      transition: all 0.5s;

      &:after {
        color: #fff;
      }
    }

    &:after {
      font-size: 24px !important;
      font-weight: 600;
      color: #000;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: $red;
  }
}

.col-xxl-auto {
  width: 20%;
}

.flight_page_filter {
  background-color: #ff5a5a;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
}
.flight_page_destination_color {
  color: #ff5a5a;
}
