@import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import "../../../node_modules/swiper/swiper-bundle.min.css";
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap");
.red_border {
  border: 2px solid red;
  border-radius: 10px !important;
}

.topheader {
  margin: 10px 4pc;
}
.topheader .container-fluid ul {
  padding: 0;
  display: flex;
  gap: 10px;
}
.topheader .container-fluid ul a {
  background-color: #FF5050;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
}

header {
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
}
header .topbar,
header .navbar {
  margin: 10px 4pc 0;
}
header.change-header .navbar .nav-link {
  color: #fff !important;
  font-weight: 300;
  letter-spacing: 2px;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.topbar {
  display: none;
}
.topbar-inner {
  display: flex;
  gap: 20px;
}
.topbar-inner .icon {
  color: #fff;
}
.topbar-inner p {
  line-height: normal;
}

.social-links {
  display: flex;
  align-items: center;
  gap: 15px;
}

.navbar {
  padding: 1pc 0;
}
.navbar .nav-link {
  font-size: 18px;
  color: #000;
  letter-spacing: 1px;
}
.navbar-nav, .navbar-right {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.main-slider {
  height: 100vh;
  position: relative;
  background-image: url("../images/slides/slide6.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.main-slider:before {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 20%, rgba(0, 0, 0, 0.3215686275) 88%);
  z-index: 9;
}
.main-slider-box {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.main-slider-content {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  z-index: 15;
  padding-bottom: 7%;
  position: relative;
}
.main-slider-content .title-section {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.main-slider-content .title-section h1.heading {
  font-weight: 600;
  font-size: 35px;
  margin-bottom: 1pc;
}
.main-slider-content .title-section h1,
.main-slider-content .title-section p {
  color: #fff;
}
.main-slider-content .mobile-search {
  margin-top: 4pc;
}
.main-slider-content .mobile-search .form-control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 1pc;
  background: rgba(255, 255, 255, 0.6509803922);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}

.main-slider-tabs ul.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  margin-bottom: 3pc;
}
.main-slider-tabs ul.tabs button {
  background: transparent;
  border: 0;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 20px;
  padding: 10px;
}
.main-slider-tabs ul.tabs button.active {
  border-bottom: 2px solid #fff;
}
.main-slider-tabs ul.tabs button img {
  height: auto;
  width: 35px;
  -o-object-fit: contain;
     object-fit: contain;
}
.main-slider-tabs .tab-content .form-control.main-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 1pc;
}
.main-slider-tabs .tab-content .form-control.main-search > input[type=search] {
  font-size: 22px;
}
.main-slider-tabs .tab-content .form-control.inner-search {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 0;
  border-radius: 6px;
}
.main-slider-tabs .tab-content .form-control.inner-search .icon {
  font-size: 22px;
}
.main-slider-tabs .tab-content .travel-info {
  display: flex;
  width: 100%;
  padding: 0 1pc;
  justify-content: space-between;
}
.main-slider-tabs .tab-content .travel-info > li {
  border-right: 1px solid #dad8d8;
  padding: 0px 1pc;
  gap: 40px;
  align-items: flex-start;
  width: -webkit-fill-available;
  cursor: pointer;
  position: relative;
}
.main-slider-tabs .tab-content .travel-info > li h5 {
  font-size: 18px;
}
.main-slider-tabs .tab-content .travel-info > li p.text {
  white-space: nowrap;
  margin-bottom: 0;
  font-size: 15px;
}
.main-slider-tabs .tab-content .travel-info .search-bar {
  bottom: -14px;
  position: absolute;
  z-index: 30;
  right: 0;
  width: 100%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1254901961);
}
.main-slider-tabs .tab-content .traveller {
  background-color: #fff;
  padding: 1pc;
  width: 681px !important;
  border-radius: 6px;
  bottom: auto !important;
  top: 40px;
}
.main-slider-tabs .tab-content .traveller.room-guest {
  bottom: -210px !important;
  height: 240px;
  overflow: hidden scroll;
}
.main-slider-tabs .tab-content .traveller p.text {
  text-align: left;
  font-size: 13px;
  font-weight: 600;
}
.main-slider-tabs .tab-content .traveller ul.number {
  display: flex;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1254901961);
  border-radius: 5px;
  width: -moz-max-content;
  width: max-content;
}
.main-slider-tabs .tab-content .traveller ul.number li {
  padding: 8px 1pc;
  font-weight: 500;
  font-size: 13px;
}
.main-slider-tabs .tab-content .traveller ul.number li.active {
  background: #FF5050;
  color: #fff;
  border-radius: 5px;
}

.custum-dropdown {
  position: absolute;
  height: 300px;
  width: -moz-max-content;
  width: max-content;
  text-align: left;
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1254901961);
  z-index: 30;
  overflow: hidden scroll;
}
.custum-dropdown > p.text-muted {
  padding: 10px;
}
.custum-dropdown p {
  line-height: normal;
  margin: 0;
}
.custum-dropdown p.text-muted {
  font-weight: 500;
  font-size: 13px;
}
.custum-dropdown .list {
  border-top: 1px solid #eee;
}
.custum-dropdown .list li {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 5px 10px;
  border-bottom: 1px solid #eee;
}
.custum-dropdown .list li p.text-muted {
  font-size: 12px;
}
.custum-dropdown .list li p.text-dark {
  font-size: 14px;
  font-weight: 500;
}

.date-picker-module {
  cursor: pointer;
  font-family: "Lexend", sans-serif;
}
.date-picker-module .react-daterange-picker__button {
  display: none;
}
.date-picker-module .react-daterange-picker__wrapper {
  border: 0;
}
.date-picker-module .react-daterange-picker__calendar {
  inset: auto auto -250px -70px !important;
}
.date-picker-module .react-calendar {
  border: 0;
  border-radius: 6px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.231372549);
}
.date-picker-module .react-calendar__navigation {
  border-radius: 6px 6px 0 0;
  background: #b7b7b7;
}
.date-picker-module .react-calendar__navigation button {
  color: #fff;
  font-weight: 600;
}
.date-picker-module .react-calendar__navigation button:enabled:hover {
  background: #FF5050;
}
.date-picker-module .react-calendar__tile--active {
  background: #f99192;
  color: white;
  font-weight: 600;
}
.date-picker-module .react-calendar__tile--hasActive {
  background: #ff5659;
  color: #fff;
  font-weight: 600;
}

.why-us {
  background: linear-gradient(90deg, rgba(181, 94, 183, 0.4588235294) 0%, rgba(84, 180, 180, 0.4039215686) 48%, rgba(105, 87, 175, 0.4078431373) 100%);
  padding: 20px 0;
}
.why-us li {
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 20px;
}
.why-us li img {
  width: auto;
  height: 50px;
  -o-object-fit: contain;
     object-fit: contain;
}
.why-us li h5 {
  margin: 0;
}

.best-off-box {
  position: relative;
  overflow: hidden;
  margin: 0 0 15px 15px;
}
.best-off-box .image {
  height: 350px;
  overflow: hidden;
  border-radius: 20px;
}
.best-off-box .image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 20px;
  transition: all 0.5s;
}
.best-off-box:hover img {
  transform: scale(1.3);
  transition: all 0.5s;
}
.best-off-box .content {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.6392156863) 100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 1pc;
  border-radius: 20px;
  text-align: center;
}
.best-off-box .content h4,
.best-off-box .content p {
  transition: all 0.5s;
  color: #fff;
  margin: 0;
}
.best-off-box.trending-destination-left .image {
  height: 250px;
}
.best-off-box.trending-destination-right .image {
  height: 515px;
}
.best-off-box.most-viewed-left .image {
  height: 250px;
}
.best-off-box.most-viewed-right .image {
  height: 515px;
}

.adventure .title-section {
  padding: 1pc;
  border-radius: 10px;
  height: 200px;
  display: flex;
  align-items: center;
}
.adventure-box {
  text-align: center;
}
.adventure-box .image {
  border-radius: 50%;
  overflow: visible;
}
.adventure-box .image img {
  height: 130px;
  width: 130px;
  -o-object-fit: cover;
     object-fit: cover;
}
.adventure-box h5 {
  margin: 1pc 0 0;
}

.about-tour-box .content {
  padding: 2pc;
}

.awards {
  padding: 3pc;
  border-radius: 10px;
}
.awards li:first-child {
  margin-top: 2pc;
}
.awards .image {
  margin-bottom: 3pc;
  text-align: center;
}
.awards .image:last-child {
  margin: 0;
}
.awards .image img {
  width: 100px;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
  transition: all 0.5s;
}
.awards .image img:hover {
  transform: scale(1.1);
  transition: all 0.5s;
}

.test-award-mid {
  border-radius: 10px;
  max-height: 495px;
  height: 100%;
}

.testimonials {
  background-image: url("../images/testimonial_bg.png");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 3pc 2pc;
  text-align: center;
  position: relative;
}
.testimonials-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 450px;
  align-items: center;
}
.testimonials-box .image img {
  height: 140px;
  width: 140px;
  border-radius: 50%;
  margin-bottom: 1pc;
}
.testimonials-box p {
  color: #000;
}
.testimonials-box .content {
  background-image: url("../images/quote.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.testimonials-box .content p {
  padding: 1pc 2pc;
}

.brand img {
  width: 100%;
  height: 145px;
  -o-object-fit: contain;
     object-fit: contain;
}
.brand .image {
  text-align: center;
}

.footer {
  -o-border-image: url("../images/decor/stamp.png") 17 round;
     border-image: url("../images/decor/stamp.png") 17 round;
  -webkit-border-image: url("../images/decor/stamp.png") 17 round;
  filter: drop-shadow(4px 5px 12px rgba(0, 0, 0, 0.07));
  border-width: 13px;
  border-style: solid;
  border-radius: 20px;
  padding: 4pc 0;
  margin: 0 10pc;
  position: relative;
}
.footer:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url("../images/decor/bg2.jpg");
  background-size: cover;
  background-position: center bottom;
  bottom: 0pc;
  left: 0;
  z-index: -1;
}
.footer .icon {
  display: flex;
  align-items: center;
  gap: 10px;
}
.footer .icon img {
  width: auto;
  height: 30px;
  -o-object-fit: contain;
     object-fit: contain;
}
.footer ul.links li {
  margin-bottom: 10px;
}
.footer ul.links li a {
  color: #797979;
}
.footer ul.links li a:hover {
  color: #FF5050;
}
.footer label {
  color: #000;
  font-weight: 500;
  font-size: 15px;
}
.footer h4.heading {
  margin-bottom: 1.5pc;
  text-transform: capitalize;
  white-space: nowrap;
}
.footer .destinations img {
  width: 100%;
  height: 100px;
  -o-object-fit: cover;
     object-fit: cover;
}
.footer .destinations img:hover {
  transform: scale(1.1);
  transition: all 0.5s;
}
.footer .logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 3pc;
}
.footer .logo .line {
  width: 100%;
  background: #ddd;
}

ul.social-links {
  margin-top: 1pc;
}
ul.social-links li {
  margin: 0;
}
ul.social-links img {
  width: auto;
  height: 25px;
  -o-object-fit: contain;
     object-fit: contain;
}

footer {
  position: relative;
  overflow: hidden;
  padding-top: 1pc;
}
footer:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url("../images/footer.jpg");
  background-size: cover;
  background-attachment: fixed;
  bottom: -6pc;
  left: 0;
  z-index: -1;
}

.copyright {
  padding: 2pc 0;
}
.copyright p,
.copyright a {
  color: #fff;
}

.questions {
  min-height: 100vh;
  overflow: hidden scroll;
  background-image: url("../images/travelers.png");
  background-position: center 101%;
  background-repeat: repeat no-repeat;
  background-size: 12pc;
  position: relative;
  padding: 2pc 5pc 5pc 5pc;
}
.questions:before {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-image: url("../images/decor/3.jpg");
  background-size: cover;
  content: "";
  z-index: -1;
}
.questions .container-fluid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.questions-inner {
  margin: 4pc 0;
}
.questions-inner .search-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1254901961);
}
.questions-inner .destination-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.questions-inner .destination-list .best-off-box {
  width: calc(20% - 15px);
  border-radius: 10px;
}
.questions-inner .destination-list .best-off-box .content,
.questions-inner .destination-list .best-off-box .image,
.questions-inner .destination-list .best-off-box .image img {
  border-radius: 10px;
}
.questions-inner .destination-list .best-off-box .image {
  height: 245px;
}
.questions-inner .destination-list .best-off-box .content {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7882352941) 100%);
}
.questions-inner .destination-list .best-off-box .content p {
  font-size: 14px;
}
.questions-inner .destination-list .best-off-box .content h4 {
  font-size: 22px;
}
.questions-inner .destination-list .best-off-box:hover .content p {
  font-size: 14px !important;
}
.questions-inner .destination-list .best-off-box:hover .content h4 {
  font-size: 22px !important;
}

ul.travel-month {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
ul.travel-month li {
  width: calc(16.6% - 25px);
  margin: 0 25px 25px 0px !important;
  background: #fff;
  border-radius: 10px;
  padding: 1pc;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1254901961);
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
ul.travel-month li img {
  width: auto;
  height: 45px;
  -o-object-fit: contain;
     object-fit: contain;
  margin: 16px 0;
}
ul.travel-month li p {
  margin: 0;
}
ul.travel-month li p.our-pick {
  color: #ff5050;
}
ul.travel-month li p.second-best {
  color: #eeba00;
}
ul.travel-month li p.off-season {
  color: #888888;
}

.travel-purpose {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.travel-purpose li {
  width: calc(25% - 25px);
  margin: 0 25px 25px 0px;
  background: #fff;
  border-radius: 10px;
  padding: 1pc 1pc;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1254901961);
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.travel-purpose li img {
  width: 100%;
  height: 150px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}
.travel-purpose li h5 {
  margin: 1pc 0 0px;
  font-size: 17px;
}

.ques-2 .date-picker-module {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  z-index: 999;
}
.ques-2 .date-picker-module .react-daterange-picker__wrapper {
  display: none;
}
.ques-2 .date-picker-module .react-daterange-picker__calendar {
  position: static !important;
}

.how-many-people {
  margin-bottom: 3pc;
}
.how-many-people .swiper {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1294117647);
  border-radius: 5px;
  background-color: #fff;
}
.how-many-people .swiper-slide {
  text-align: center;
}
.how-many-people .swiper-slide:nth-child(odd) .num {
  background-color: #f4f4f4;
}
.how-many-people .num {
  padding: 8px 1pc;
  font-weight: 500;
  cursor: pointer;
  color: #000;
}
.how-many-people .num.active {
  background: #ff5050;
  color: #fff;
  border-radius: 5px;
}

.select-age > .d-flex {
  -moz-column-gap: 25px;
       column-gap: 25px;
  row-gap: 25px;
}
.select-age label {
  display: flex;
  align-items: center;
  gap: 20px;
}
.select-age label h5 {
  white-space: nowrap;
}
.select-age-box {
  background: #fff;
  border-radius: 10px;
  padding: 10px 1pc;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1254901961);
  width: -moz-max-content;
  width: max-content;
}
.select-age-box .form-check-input {
  height: 40px;
  width: 40px;
  background-color: #f4f4f4;
  border: 0;
  margin: 0;
}
.select-age-box .form-check-input:checked {
  background-color: #FF5050;
}
.select-age-box .form-check-input:focus {
  box-shadow: none;
}

.timeline {
  display: flex;
  align-items: center;
  justify-content: center;
}
.timeline li {
  margin-right: 150px;
  position: relative;
}
.timeline li.half .dot {
  background-color: #FF5050;
  height: 30px;
  width: 30px;
  position: relative;
}
.timeline li.half .dot:before {
  content: "";
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  z-index: 5;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.timeline li.half .half-filled {
  position: absolute;
  height: 100px;
  width: 110px;
  top: 50%;
  transform: translateY(-50%);
  left: 0%;
}
.timeline li.half .half-filled .half-line {
  background-color: #FF5050;
  height: 2px;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.timeline li.half .half-filled img {
  position: absolute;
  top: 50%;
  transform: translateY(-100%);
  right: -5px;
  height: auto;
  width: 50px;
  -o-object-fit: contain;
     object-fit: contain;
}
.timeline li.half:last-child .half-line {
  display: none;
}
.timeline li.half:last-child .half-filled {
  left: -3pc;
}
.timeline li.half:last-child .half-filled img {
  right: auto;
  left: 0;
}
.timeline li:not(:last-child) .empty-line {
  background-color: #d9d9d9;
  height: 2px;
  width: 150px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
}
.timeline li:not(:last-child) .full-filled {
  background-color: #FF5050;
  height: 2px;
  width: 150px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  z-index: 4;
}
.timeline li:not(:last-child).full .dot {
  background-color: #FF5050;
}
.timeline .dot {
  height: 20px;
  width: 20px;
  background-color: #d9d9d9;
  border-radius: 50%;
  cursor: pointer;
}
.timeline.verticle-timeline {
  flex-direction: column;
}
.timeline.verticle-timeline li {
  margin-right: 0;
  margin-bottom: 100px;
}
.timeline.verticle-timeline li:not(:last-child) .empty-line {
  width: 2px;
  height: 135px;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0px);
}
.timeline.verticle-timeline li:not(:last-child) .full-filled {
  width: 2px;
  height: 135px;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0px);
}
.timeline.verticle-timeline li .dot {
  z-index: 4;
}
.timeline.verticle-timeline li.half .half-filled {
  width: 2px;
  height: 75px;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0px);
}
.timeline.verticle-timeline li.half .half-filled .half-line {
  width: 100%;
  height: 100%;
}

.ques-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  padding: 1pc 0;
}
.ques-footer button {
  border-width: 2px;
}
.ques-footer button.bg-white {
  border-color: #FF5050;
  color: #FF5050;
}
.ques-footer button.bg-white:hover {
  background-color: #FF5050 !important;
  color: #fff;
}
.ques-footer button.red-bg:hover {
  background-color: #fff !important;
  color: #FF5050;
  border-color: #FF5050;
}

.page-banner img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
.page-banner-1 {
  position: relative;
}
.page-banner-1 img {
  height: 50vh;
}
.page-banner-2 {
  position: relative;
}
.page-banner-2 .image {
  margin: 0 10px 10px 0;
}
.page-banner-2 .image.left {
  height: 510px;
}
.page-banner-2 .image.right {
  height: 250px;
}
.page-banner-3 {
  background-size: cover;
  background-attachment: fixed;
  height: 60vh;
  display: flex;
  align-items: center;
  position: relative;
}
.page-banner-3 .page-banner-content {
  padding: 2pc;
  background: rgba(0, 0, 0, 0.5019607843);
  border-radius: 20px;
}
.page-banner-3 .page-banner-content p {
  letter-spacing: 2px;
  font-size: 20px;
  font-weight: 200;
  line-height: 25px;
}

.sort-by__value-container {
  width: 160px;
  font-size: 14px;
}
.sort-by__input-container {
  margin: 0;
  padding: 0;
}
.sort-by__control {
  min-height: 40px !important;
  border-color: rgba(206, 212, 218, 0.6392156863) !important;
}
.sort-by__indicator {
  padding: 0 8px;
}
.sort-by__indicator-separator {
  background-color: rgba(206, 212, 218, 0.6392156863) !important;
}
.sort-by__menu {
  font-size: 14px;
}

.packages {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}
.packages-left {
  width: 18%;
}
.packages-box {
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1254901961);
  border-radius: 10px;
  padding: 1pc;
}
.packages-head {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.packages-head .search-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-color: #068a4f;
}
.packages-inner-left {
  overflow: hidden;
  border-radius: 10px;
}
.packages-inner-left img {
  border-radius: 10px;
  height: 100%;
  transition: all 1.5s cubic-bezier(0.18, 1.06, 0.72, -0.39);
}
.packages-inner:hover .packages-inner-left img {
  transform: scale(1.4);
  transition: all 1.5s cubic-bezier(0.18, 1.06, 0.72, -0.39);
}
.packages-inner-right {
  padding: 1pc 2pc;
}
.packages-inner-right p.price {
  display: flex;
  align-items: baseline;
  color: #000;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 7px;
}
.packages-inner-right .heading {
  justify-content: space-between;
  margin-bottom: 1pc;
}
.packages-inner-right ul.offer {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-top: 1pc;
}
.packages-inner-right ul.offer img {
  width: auto;
  -o-object-fit: contain;
     object-fit: contain;
  height: 30px;
  transition: all 0.5s;
}
.packages-inner-right ul.offer img:hover {
  transform: scale(1.2);
  transition: all 0.5s;
}
.packages-inner-right ul.detail {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 1pc;
}
.packages-inner-right ul.detail li {
  display: flex;
  align-items: baseline;
  gap: 7px;
  color: #FF5050;
  font-weight: 600;
  font-size: 17px;
}
.packages-inner-right ul.detail li .icon {
  font-size: 19px;
}
.packages-inner-right .itinarary {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin: 1.5pc 0;
}
.packages-inner-right .itinarary li {
  background-color: #ffe4c3;
  color: #000;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
}
.packages-inner-right .info {
  display: flex;
  align-items: center;
  gap: 30px;
}
.packages-inner-right .info li {
  color: #8383a9;
  font-weight: 500;
}
.packages-inner-right .info li:not(:last-child) {
  position: relative;
}
.packages-inner-right .info li:not(:last-child):before {
  content: "";
  position: absolute;
  height: 8px;
  width: 8px;
  background: #8383a9;
  top: 50%;
  border-radius: 50%;
  transform: translateY(-50%);
  right: -18px;
}
.packages-inner-right .info + ul.offer img {
  height: 25px;
}

.filters.sticky-top {
  top: 1pc;
  height: 100vh;
  overflow: hidden scroll;
}
.filters-title {
  text-transform: capitalize;
  letter-spacing: 0.04em;
  font-weight: 500;
  font-size: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 1pc;
  line-height: normal;
  color: #000;
}
.filters-list {
  color: #36454f;
  max-height: 210px;
  overflow: auto;
  padding: 5px 0;
}
.filters-list::-webkit-scrollbar {
  width: 5px;
}
.filters-list::-webkit-scrollbar-track {
  background: #d8d8d8;
}
.filters-list::-webkit-scrollbar-thumb {
  background-color: #000;
}
.filters-list-title {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}
.filters-list-title label,
.filters-list-title input {
  cursor: pointer;
}
.filters-list-title:hover input[type=checkbox] {
  border-color: #FF5050;
}
.filters-list-title:hover input[type=checkbox]:before {
  opacity: 1 !important;
  transform: scale(1) !important;
  transition: all 0.1s;
}
.filters-list li:last-child .filters-list-title {
  margin-bottom: 0;
}
.filters-box {
  margin-bottom: 1pc;
}

input[type=checkbox] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  height: 15px;
  width: 15px;
  border: 1px solid #d0d0d0;
  position: relative;
  background-color: #fff;
  border-radius: 0;
}
input[type=checkbox]:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  bottom: 0;
  background-image: url("../images/icons/check.png");
  background-size: 100% 100%;
  background-color: rgba(255, 255, 255, 0);
  background-position: center top;
  background-repeat: no-repeat;
  transform: scale(0);
  transition: all 0.1s;
  opacity: 0;
}
input[type=checkbox]:checked {
  border-color: #FF5050;
  background-color: #fff;
}
input[type=checkbox]:checked:before {
  opacity: 1;
  transform: scale(1);
  transition: all 0.1s;
}
input[type=checkbox]:not(:checked):before {
  opacity: 0;
  transform: scale(0);
  transition: all 0.1s;
}

.price-range {
  color: #FF5050 !important;
}

.whatsapp {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 2pc;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.1254901961));
  display: flex;
  align-items: center;
  gap: 25px;
  z-index: 9999999;
}
.whatsapp .expert-talk img {
  height: 60px;
  cursor: pointer;
}
.whatsapp img {
  -o-object-fit: contain;
     object-fit: contain;
  width: auto;
}
.whatsapp img.icon {
  height: 50px;
}
.whatsapp img.text {
  position: absolute;
  height: 29px;
  left: -23px;
  top: -33px;
  z-index: -1;
  transition: all 0.5s;
  z-index: -1;
  transition: all 0.5s;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
.faq {
  background-image: url("../images/woman_walking.png");
  background-repeat: no-repeat;
  background-position: 80% center;
  background-size: contain;
  padding: 80px 0 6pc;
}
.faq .gray-bg {
  padding: 3pc;
}
.faq details summary {
  color: #000;
  padding: 10px 1pc;
  font-size: 17px;
}
.faq details .content {
  padding: 0 1pc;
}
.faq details .content p {
  line-height: 1.6;
  font-size: 15px;
  margin-bottom: 10px;
}
.faq details[open] .content {
  animation: sweep 0.5s ease-in-out;
}

.trip-info {
  background-color: #faeded;
}
.trip-info ul {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 20px;
  background-color: #fff;
  border-radius: 20px;
  padding: 2pc 1pc;
}
.trip-info ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.trip-info ul li .icon {
  font-size: 30px;
  color: #FF5050;
}
.trip-info ul li h5 {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 5px;
}
.trip-info ul li p {
  font-size: 18px;
  color: #000;
}

ul.trip-includes {
  display: flex;
  flex-wrap: wrap;
  gap: 4pc;
}
ul.trip-includes li {
  text-align: center;
}
ul.trip-includes li img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
ul.trip-includes li .icon {
  background: #d6d3fd;
  border-radius: 20px;
  height: 70px;
  width: 70px;
  padding: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
ul.trip-includes li h5 {
  margin: 1pc 0 0;
  font-size: 16px;
}

.custom-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  z-index: 9999;
}
.custom-modal:focus-visible {
  outline: 0;
}
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5019607843);
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
  z-index: 999;
}
.custom-modal-body {
  overflow: hidden;
}

.talk-to-expert {
  background-color: #f5f3fb;
  padding: 2pc;
  border-radius: 10px;
  max-width: 600px;
  min-width: 460px;
}
.talk-to-expert .form label {
  color: #000;
  font-size: 14px;
}
.talk-to-expert .form .form-control {
  background: #fff;
  padding: 10px;
}
.talk-to-expert .heading {
  color: #877ed9;
  line-height: normal !important;
  text-transform: inherit !important;
}
.talk-to-expert .heading + p {
  color: #000;
  font-size: 16px !important;
  line-height: normal !important;
  margin-bottom: 0;
}

.privacy-policy {
  position: relative;
}
.privacy-policy:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url("../images/decor/plane2.png");
  background-repeat: no-repeat;
  background-position: center;
  top: 0;
  left: 0;
  opacity: 0.3;
  z-index: -1;
}

.faq-page {
  position: relative;
}
.faq-page:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url("../images/woman_walking.png");
  background-repeat: no-repeat;
  background-position: right center;
  top: 0;
  left: 0;
  opacity: 0.3;
  z-index: -1;
}
.faq-page details {
  background: #faeded;
  border-radius: 10px;
  margin-bottom: 1pc;
  padding: 10px;
}
.faq-page .faq {
  background: none;
}

.about-right {
  height: 520px;
}

.why-us-box {
  text-align: center;
}
.why-us-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.why-us-box .icon {
  background: #ffdddd;
  border-radius: 20px;
  height: 110px;
  width: 110px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  transition: all 1s;
}
.why-us-box h5 {
  margin: 1.5pc 0 10px;
}
.why-us-box p {
  margin-bottom: 0;
}
.why-us-box:hover .icon {
  box-shadow: 0 0 1px 7px rgba(255, 80, 80, 0.6784313725), 0 0 20px rgba(0, 0, 0, 0.1254901961);
  transition: all 1s;
}

.our-team-box .image {
  height: 300px;
  border-radius: 20px;
}
.our-team-box .image img {
  height: 100%;
  width: 100%;
}

.blog-box .image {
  height: 300px;
  border-radius: 20px;
  position: relative;
}
.blog-box .image img {
  height: 100%;
  width: 100%;
}
.blog-box .content {
  margin: 10px 0 0;
}
.blog-box .content p {
  line-height: 20px;
  font-size: 14px;
}
.blog-box .tags {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.7215686275);
  -webkit-backdrop-filter: blur(1px) sepia(24);
          backdrop-filter: blur(1px) sepia(24);
  padding: 10px 1pc;
}
.blog-box .tags li {
  font-weight: 500;
}
.blog-box .tags li:not(:last-child) {
  position: relative;
  padding-right: 10px;
  margin-right: 8px;
}
.blog-box .tags li:not(:last-child):before {
  content: "|";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  font-weight: 700;
}

.newsletter {
  background-image: url("../images/slides/slide4.jpg");
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5294117647);
}
.newsletter .form-control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 1pc;
}

.testimonials-style-2-box {
  background-color: #fff;
}
.testimonials-style-2-box .image {
  height: 455px;
  border-radius: 20px;
}
.testimonials-style-2-box .image img {
  height: 100%;
  width: 100%;
}
.testimonials-style-2-box .right p {
  font-weight: 300;
}
.testimonials-style-2-box .right .content {
  margin: 1pc 0;
}
.testimonials-style-2-box .right .name h5 {
  font-weight: 600;
  color: #FF5050;
  margin: 0;
}
.testimonials-style-2-box .right .name p {
  color: #000;
}
.testimonials-style-2 .swiper-button-prev, .testimonials-style-2 .swiper-button-next {
  top: auto !important;
  bottom: 0 !important;
  border-radius: 0 !important;
}
.testimonials-style-2 .swiper-button-prev {
  left: 0;
}
.testimonials-style-2 .swiper-button-next {
  right: auto !important;
  left: 56px !important;
}

.single-blog .image {
  height: 50vh;
}
.single-blog .tags {
  position: static;
}
.single-blog .content {
  margin-top: 1pc;
}
.single-blog .content p {
  line-height: 1.8em;
  font-size: 16px;
  font-weight: 300;
}

ul.filter-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background: #f8f8f8;
  padding-bottom: 1px;
}
ul.filter-tabs .btn {
  /* box-shadow: 0 0 20px rgb(0 0 0 / 13%); */
  font-weight: 500;
  padding: 15px 2pc;
  transition: all 0.5s;
  white-space: nowrap;
  border: 1px solid #eee;
  border-width: 0 1px !important;
  border-radius: 0;
  background: #fff;
}
ul.filter-tabs .btn.active {
  background: #FF5050;
  color: #fff;
}
ul.filter-tabs .btn:hover {
  background: #FF5050;
  color: #fff;
  transition: all 0.5s;
}

.trending-blog .row {
  border-radius: 20px;
}
.trending-blog .content {
  padding: 3pc;
}
.trending-blog .image {
  height: 430px;
  border-radius: 0 20px 20px 0;
}

.testimonials-style-3-box {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1254901961);
  padding: 2pc;
  border-radius: 20px;
  transition: all 0.5s;
}
.testimonials-style-3-box:hover {
  transform: scale(0.95);
  transition: all 0.5s;
}
.testimonials-style-3-box .image {
  height: 80px;
  width: 80px;
  border-radius: 50%;
}

.career-apply-box {
  background-color: #ffecec;
  padding: 2pc;
  border-radius: 20px;
  transition: all 0.5s;
}
.career-apply-box:hover {
  transform: scale(0.97);
  transition: all 0.5s;
}
.career-apply-box .image {
  height: 225px;
  border-radius: 20px;
}

.trip-price-detail {
  background-color: #fff;
  border: 1px solid #FF5050;
  padding: 2pc;
  border-radius: 20px;
  margin-top: -8pc;
}
.trip-price-detail li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dcd8d8;
  padding: 10px 10px;
  color: rgba(0, 0, 0, 0.7411764706);
  gap: 10px;
}
.trip-price-detail li span {
  font-weight: 600;
  color: #000;
}
.trip-price-detail.style-2 {
  background-color: #fff;
  border: 1px solid #FF5050;
  padding: 0;
}
.trip-price-detail.style-2 .box {
  border: 1px solid #dcd8d8;
  border-radius: 5px;
  padding: 10px 1pc;
  margin: 15px 10px;
}
.trip-price-detail.style-2 ul {
  margin: 15px 10px;
  padding: 0 !important;
}
.trip-price-detail.style-2 ul li {
  padding: 10px 1pc;
}
.trip-price-detail.style-2 ul li:last-child {
  border: 0;
}
.trip-price-detail.style-2 .top {
  border-bottom: 1px solid #dcd8d8;
  text-align: center;
  padding: 10px;
}

.trip-desp .date-picker-module {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5019607843);
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
  z-index: 999;
}
.trip-desp .date-picker-module .react-daterange-picker__wrapper {
  display: none;
}
.trip-desp .date-picker-module .react-daterange-picker__calendar {
  position: static !important;
}

.trip-dates .swiper-slide:nth-child(3n+1) .trip-dates-box {
  box-shadow: inset 0 0 0 2000px rgba(206, 210, 255, 0.768627451);
}
.trip-dates .swiper-slide:nth-child(3n+2) .trip-dates-box {
  box-shadow: inset 0 0 0 2000px rgba(255, 228, 197, 0.768627451);
}
.trip-dates .swiper-slide:nth-child(3n+3) .trip-dates-box {
  box-shadow: inset 0 0 0 2000px rgba(197, 241, 255, 0.768627451);
}
.trip-dates-box {
  padding: 2pc;
  border-radius: 20px;
  background-color: #fff;
  background-image: url("../images/decor/paper.png");
  background-size: contain;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.0666666667);
}
.trip-dates-box .heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trip-dates-box .price {
  margin-bottom: 0;
}
.trip-dates-box ul.trip-timeline {
  margin: 1pc 0;
  position: relative;
}
.trip-dates-box ul.trip-timeline:before {
  content: "";
  position: absolute;
  height: 100%;
  border-left: 2px solid #ff5355;
  left: 7.5px;
  top: 0;
  z-index: 0;
}
.trip-dates-box ul.trip-timeline li {
  display: flex;
  align-items: flex-start;
  gap: 17px;
  color: #000;
  margin-bottom: 1pc;
}
.trip-dates-box ul.trip-timeline li h6 {
  margin: 0;
  color: #ff5355;
  font-size: 18px;
}
.trip-dates-box ul.trip-timeline li .icon {
  background: #ff5355;
  height: 15px;
  width: 15px;
  border-radius: 50%;
}
.trip-dates-box .flex-timeline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #eee;
  border-width: 1px 0 !important;
  margin-bottom: 1pc;
}

.trip-all-detail .itinerary-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

ul.trip-itinerary li {
  margin-bottom: 2pc;
}
ul.trip-itinerary li .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
ul.trip-itinerary li .title h6 {
  margin-bottom: 0;
}
ul.trip-itinerary li .title.active .icon {
  transform: scaleY(-1);
  transition: all 0.5s;
}
ul.trip-itinerary li .title .icon {
  transition: all 0.5s;
  color: #000;
  font-size: 22px;
}
ul.trip-itinerary li p {
  font-size: 15px;
  font-weight: 300;
}
ul.trip-itinerary li span {
  color: #ff5050;
  background: #faeded;
  padding: 1px 14px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
}

.trip-package-box {
  padding: 2pc;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1294117647);
  text-align: center;
}
.trip-package-box h4.price {
  margin-bottom: 1pc;
  text-decoration: line-through;
  color: gray;
}
.trip-package-box h2.price {
  font-size: 26px;
  margin: 0;
}
.trip-package-box h6 {
  border-bottom: 1px solid #ddd;
  padding-bottom: 1pc;
  margin: 1pc 0;
}

.trip-pickup-drop ul {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1pc;
  gap: 28px;
}
.trip-pickup-drop ul h5 {
  margin: 0;
}
.trip-pickup-drop ul .line {
  width: 40px;
  height: 2px;
  background: #232323;
}
.trip-pickup-drop ul .icon {
  font-size: 35px;
}

.trip-overview p {
  margin: 0;
}
.trip-overview h5 {
  margin: 1pc 0;
}
.trip-overview ul {
  margin: 1pc 0;
}
.trip-overview ul.quick-info li {
  display: flex;
  align-items: center;
  gap: 10px;
}
.trip-overview ul.quick-info li span {
  color: #000;
  font-weight: 500;
}

ul.not-to-miss {
  margin: 1pc 0;
}
ul.not-to-miss li {
  display: flex;
  gap: 10px;
}
ul.not-to-miss li .icon {
  color: #FF5050;
}

.inclusion-exclusion ul:not(:last-child) {
  margin-bottom: 2pc;
}
.inclusion-exclusion li {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  color: #000;
  margin-bottom: 10px;
}
.inclusion-exclusion li .icon {
  font-size: 22px;
}

.checkout-box {
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1254901961);
  border-radius: 10px;
  padding: 2pc;
}

.sign-in {
  height: 100vh;
  overflow: hidden;
  background-color: #f6f6f6;
}
.sign-in .right {
  height: calc(100% - 2pc);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #fff;
  margin: 1pc;
  border-radius: 20px;
  padding: 2pc 6pc;
  overflow: hidden;
}
.sign-in .left {
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  padding-bottom: 12pc;
}
.sign-in .left img.left-image {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  height: 500px;
}
.sign-in .left .boxes {
  position: absolute;
  bottom: 1pc;
  left: 0;
  width: 100%;
}
.sign-in-with-timeline .form [class*=col-] {
  margin-bottom: 40px;
}

.home-trip-tabs {
  position: fixed;
  bottom: 0;
  z-index: 200;
  background: #fff;
  padding: 16px 0;
  width: 100%;
  border-radius: 16px 16px 0 0;
  box-shadow: 0px -8px 20px rgba(0, 0, 0, 0.1411764706);
}
.home-trip-tabs .inner {
  border-radius: 10px;
}
.home-trip-tabs .tabs {
  gap: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.home-trip-tabs .tabs button {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4px;
  font-size: 12px;
  line-height: normal;
  padding: 0;
  font-weight: 500;
  font-size: 13px;
  color: #000;
}
.home-trip-tabs .tabs button span {
  display: none;
}
.home-trip-tabs .tabs button.active {
  color: #FF5050;
}
.home-trip-tabs .tabs button.active .img {
  background: #FF5050;
}
.home-trip-tabs .tabs button .img {
  height: 40px;
  width: 40px;
  padding: 10px;
  margin: 0;
  background: #FF5050;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.home-trip-tabs .tabs button img {
  -o-object-fit: contain;
     object-fit: contain;
  height: 100%;
  width: 100%;
}
.home-trip-tabs .show-tab {
  margin: 10px !important;
}
.home-trip-tabs .show-tab {
  margin: 1pc 0;
}
.home-trip-tabs .show-tab.form-control {
  max-width: -webkit-fill-available;
}
.home-trip-tabs .form label {
  color: #000;
}
.home-trip-tabs .form .form-control,
.home-trip-tabs .form .form-select {
  padding: 5px 10px;
  font-size: 14px;
}
.home-trip-tabs .traveller.room-guest {
  height: 240px;
  overflow: hidden scroll;
}
.home-trip-tabs .traveller p.text {
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}
.home-trip-tabs .date-picker-module .react-daterange-picker {
  width: 100%;
}
.home-trip-tabs .date-picker-module .react-daterange-picker__wrapper {
  border: 1px solid #ebebeb;
  padding: 3px 10px;
  border-radius: 5px;
  background-color: #f8f8f8;
  width: 100%;
}
.home-trip-tabs .date-picker-module .react-daterange-picker__inputGroup:not(:first-child) {
  text-align: right;
}

.flights {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}
.flights .box-head {
  border-bottom: 1px solid #FF5050;
  padding: 10px 1pc;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flights-box {
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1254901961);
  border-radius: 10px;
  padding: 1pc;
}
.flights-head {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  color: #36454f;
}
.flights-head label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
}
.flights-left {
  width: 22%;
  top: 1pc;
  height: 100vh;
}
.flights-left .flights-box {
  border: 1px solid #FF5050;
  box-shadow: none;
  padding: 0;
}
.flights-left .box {
  margin-bottom: 1pc;
  padding: 1pc;
  overflow-y: auto;
  max-height: 90vh;
}
.flights-left .box:last-child {
  margin-bottom: 0;
}
.flights-left .title {
  text-transform: capitalize;
  letter-spacing: 0.04em;
  font-weight: 500;
  padding-bottom: 10px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 1pc;
  line-height: normal;
  color: #000;
}
.flights-left .trip-price-detail {
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
}
.flights-left .selected-flight {
  padding: 1pc;
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
}
.flights-left .selected-flight > p.text-center {
  border-bottom: 2px solid #ff5355;
  width: -moz-max-content;
  width: max-content;
  margin: 0 auto 1pc;
}
.flights-left .selected-flight .image {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin: 0 auto 10px;
}
.flights-left .selected-flight .image img {
  height: 100%;
  width: 100%;
}
.flights-left .selected-flight ul.d-flex {
  justify-content: space-evenly;
}
.flights-inner {
  display: flex;
  gap: 15px;
  align-items: center;
  margin: 1pc 0;
}
.flights-inner .left .image {
  height: 100px;
  width: 100px;
  border-radius: 10px;
}
.flights-inner .right {
  justify-content: space-around;
  display: flex;
  gap: 15px;
}

.account-banner {
  background-image: url("../images/profile_page.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 20px;
  height: 50vh;
  /* box-shadow: inset 0 0 0 2000px #00000060; */
  display: flex;
  align-items: flex-end;
  position: relative;
}
.account-banner .container-fluid {
  position: relative;
  z-index: 5;
}
.account-banner ul.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1px;
  overflow: hidden;
  position: relative;
  padding: 0 40px;
}
.account-banner ul.tabs li {
  background: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 2pc;
  position: relative;
  font-weight: 500;
  color: #000;
  cursor: pointer;
  white-space: nowrap;
  border-right: 1px solid #cdcdcd;
  transform: skewX(-30deg);
}
.account-banner ul.tabs li:first-child {
  border-top-left-radius: 5px;
}
.account-banner ul.tabs li:last-child {
  border-top-right-radius: 5px;
}
.account-banner ul.tabs li p,
.account-banner ul.tabs li .icon {
  transform: skewX(30deg);
}
.account-banner ul.tabs li.active {
  background-color: #FF5050;
  color: #fff;
}
.account-banner ul.tabs li.active .icon {
  color: #fff !important;
}
.account-banner ul.tabs li .icon {
  font-size: 18px;
}

.account-profile {
  padding-top: 4pc;
}
.account-profile .user-name {
  margin-top: -80px;
  background: #fff;
  width: -moz-max-content;
  width: max-content;
}
.account-profile .user-name .image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 3px solid #fff;
}
.account-profile .trip-price-detail {
  top: 6pc;
}
.account-profile .iternary-main {
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1254901961);
  border-radius: 15px;
  padding: 1pc;
}
.account-profile .iternary-main .content {
  padding: 0 1pc;
}
.account-profile .iternary-main .content p {
  line-height: normal;
}
.account-profile .iternary-main.small-box .content h4 {
  font-size: 20px;
}
.account-profile .iternary-main .img {
  width: 100%;
  max-height: 400px;
  height: 100%;
  border-radius: 15px;
}
.account-profile .iternary-main .img img {
  border-radius: inherit;
}

.account-booking .col-12:nth-child(even) .packages-box {
  background-color: rgba(255, 228, 197, 0.4);
}
.account-booking .col-12:nth-child(odd) .packages-box {
  background-color: rgba(206, 210, 255, 0.4);
}
.account-booking .packages-box {
  transition: all 0.5s;
  box-shadow: none;
}
.account-booking .packages-box:hover {
  transform: scale(0.99);
  transition: all 0.5s;
}

.search-flight-form {
  background-image: url("../images/slides/slide4.jpg");
  background-position: center;
  background-attachment: fixed;
  padding: 3pc 0;
}
.search-flight-form-inner {
  padding: 2pc;
  background-color: rgba(255, 255, 255, 0.8509803922);
  border: 1px solid #fff;
  border-radius: 20px;
}
.search-flight-form-inner .date-picker-module .react-daterange-picker {
  width: 100%;
}
.search-flight-form-inner .date-picker-module .react-daterange-picker__wrapper {
  border: 1px solid #ebebeb;
  padding: 10px 10px;
  border-radius: 5px;
  background-color: #ffffff;
  width: 100%;
}
.search-flight-form-inner .date-picker-module .react-daterange-picker__inputGroup:not(:first-child) {
  text-align: right;
}
.search-flight-form-inner .form {
  align-items: center;
}
.search-flight-form-inner .form .col-auto {
  width: 20%;
}
.search-flight-form-inner .form-control {
  background-color: #fff !important;
}
.search-flight-form-inner .form .traveller-absolute {
  position: absolute;
  width: 100%;
  bottom: -85%;
  padding: 10px;
  background-color: #fff !important;
}
.search-flight-form-inner .form label {
  color: #000;
}
.search-flight-form-inner .form .select-traveller__value-container {
  padding: 10px 10px;
}
.search-flight-form-inner .form .select-traveller__input-container {
  margin: 0;
  padding: 0;
}
.search-flight-form-inner .form .select-traveller__indicator {
  padding-top: 0;
  padding-bottom: 0;
}
.search-flight-form-inner .form .select-traveller__control {
  border: 0;
  border-radius: 5px;
}

.deyor-coins .price .text-warning {
  text-shadow: 2px 1px 1px rgba(0, 0, 0, 0.6588235294);
}

.get-visa {
  background-image: url("../images/visa-bg.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4196078431);
}
.get-visa .sort-by__value-container {
  padding: 7px 10px;
}
.get-visa .sort-by__control {
  border-radius: 5px;
}
.get-visa .visa-box {
  text-align: center;
  background-color: #fff;
  padding: 2pc;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1254901961);
  transition: all 0.5s;
}
.get-visa .visa-box:hover {
  transform: scale(0.98);
  transition: all 0.5s;
}
.get-visa .visa-box .icon {
  margin-bottom: 2pc;
}

.visa-procedure {
  width: 748px;
}
.visa-procedure ul.tabs {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #cad0d0;
}
.visa-procedure ul.tabs li {
  color: #000;
  padding: 10px 2pc;
  border-right: 1px solid #cad0d0;
  font-size: 14px;
  cursor: pointer;
}
.visa-procedure ul.tabs li.active {
  color: #fff;
  background: #FF5050;
}
.visa-procedure .content {
  padding: 1pc 2pc;
  height: 40vh;
  overflow: hidden scroll;
}
.visa-procedure .content::-webkit-scrollbar {
  width: 5px;
}
.visa-procedure .content::-webkit-scrollbar-track {
  background: #d8d8d8;
}
.visa-procedure .content::-webkit-scrollbar-thumb {
  background-color: #000;
}
.visa-procedure .content li,
.visa-procedure .content p {
  line-height: normal;
  font-size: 15px;
}
.visa-procedure .content h6 {
  margin: 1pc 0 10px;
}
.visa-procedure .content p {
  margin: 10px 0;
}

.get-traveller-data {
  background-color: #fff;
  padding: 1pc;
  width: 681px !important;
  border-radius: 6px;
  position: absolute;
  right: 0;
  z-index: 99;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.062745098);
  margin-top: 10px;
}
.get-traveller-data.room-guest {
  height: 240px;
  overflow: hidden scroll;
}
.get-traveller-data p.text {
  text-align: left;
  font-size: 13px;
  font-weight: 600;
}
.get-traveller-data ul.number {
  display: flex;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1254901961);
  border-radius: 5px;
  width: -moz-max-content;
  width: max-content;
}
.get-traveller-data ul.number li {
  padding: 2px 12px;
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
}
.get-traveller-data ul.number li.active {
  background: #FF5050;
  color: #fff;
  border-radius: 5px;
}

.room-category-slider {
  width: 100%;
}
.room-category-slider img {
  height: 300px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}

.hotel-despcription .filter-tabs {
  background-color: #fff;
  gap: 20px;
  justify-content: start;
}
.hotel-despcription .filter-tabs .btn {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #f4f2f2;
  border: 1px solid #c7c7c7 !important;
  border-radius: 5px;
  padding: 10px 3pc;
}
.hotel-despcription .filter-tabs .btn.active {
  border-color: #FF5050 !important;
}
.hotel-despcription .service-btn.btn {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 2px dotted #c7c7c7;
  padding: 10px 1pc;
  color: #797878;
}
.hotel-despcription .service-btn.btn img {
  height: 20px;
  width: auto;
  -o-object-fit: contain;
     object-fit: contain;
}

.hotel-slider {
  margin: 0 1pc 1pc;
}
.hotel-slider-box {
  height: 80vh;
  width: 100%;
}
.hotel-slider-box img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}

.itinerary-top-banner {
  background-image: url("../images/slides/slide4.jpg");
  background-attachment: fixed;
  background-size: cover;
}
.itinerary-top-banner .content {
  text-align: center;
  background: rgba(255, 255, 255, 0.7607843137);
  padding: 2pc;
  margin: 0 auto;
  border-radius: 16px;
  border: 1px solid #fff;
}
.itinerary-top-banner .content h1 {
  margin-bottom: 1.5pc;
}
.itinerary-top-banner .content ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.itinerary-top-banner .content ul li {
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 600;
}

.why-deyor-box {
  background-color: #ffebff;
  padding: 2pc;
}
.why-deyor-box li {
  color: #000;
}

ul.filter-1 {
  display: flex;
  gap: 20px;
  align-items: center;
  font-weight: 700;
  padding: 25px 0;
  color: #888888;
  margin-bottom: 0 !important;
}
ul.filter-1 li {
  padding: 0 10px;
  cursor: pointer;
}
ul.filter-1 li.active {
  border-bottom: 2px solid #000;
  color: #000;
}

ul.filter-2 {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 20px 0;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}
ul.filter-2 li {
  font-weight: 700;
  color: gray;
  cursor: pointer;
  background-color: white;
  box-shadow: inset 0px 2px 10px rgba(0, 0, 0, 0.06);
  border-radius: 22px;
  padding: 8px 2pc;
  display: flex;
  align-items: center;
  gap: 7px;
}
ul.filter-2 li .icon {
  font-size: 18px;
}
ul.filter-2 li.active {
  color: #FF5050;
}

.edit-option {
  display: flex;
  align-items: center;
  gap: 7px;
}
.edit-option button {
  border: 0;
  background-color: transparent;
  font-weight: 500;
}

.flight-info .box {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.062745098);
  border-radius: 10px;
  background-color: #ffffff;
  padding: 0px 15px;
}
.flight-info .box .image {
  overflow: hidden;
  width: auto;
  height: 70px;
  position: relative;
  border-radius: 3px;
}
.flight-info .box .image img {
  height: 100%;
  width: 100%;
}
.flight-info .box .image .absolute {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.6901960784);
  color: red;
  font-weight: 500;
  text-align: center;
}
.flight-info .box .content {
  padding: 2pc 15px;
}
.flight-info .box .content ul {
  display: flex;
  align-items: center;
  gap: 1pc;
  justify-content: space-evenly;
}
.flight-info .box .content ul img.plane-img {
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
  width: 300px;
}
.flight-info .box .content ul p {
  margin: 0;
  line-height: normal;
}
.flight-info .box .content ul li:last-child {
  border-left: 1px solid #797979;
  padding: 1pc 0 1pc 1pc;
}
.flight-info .box .content ul:not(:last-child) {
  border-bottom: 1px solid #eee;
  padding-bottom: 14px;
  margin-bottom: 14px;
}
.flight-info .box .borderbtom {
  border-bottom: 1px solid #e7e7e7;
}

.hotel-img-slider .heading, .flight-info .heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 2pc;
  border-bottom: 1px solid #eee;
  padding-bottom: 1pc;
  margin-bottom: 3pc;
}
.hotel-img-slider .heading h4, .flight-info .heading h4 {
  margin: 0;
}

ul.initinery-detail-box {
  padding: 0 2pc;
}
ul.initinery-detail-box > li {
  display: flex;
  align-items: flex-start;
  gap: 30px;
  padding-bottom: 1pc;
  position: relative;
}
ul.initinery-detail-box > li:not(:last-child):before {
  content: "";
  position: absolute;
  height: 100%;
  width: 1px;
  background: #d9d9d9;
  left: 8pc;
  top: 0;
}
ul.initinery-detail-box > li.active .dot {
  background: #ffc107;
}
ul.initinery-detail-box p.day {
  color: #8b85c2;
  font-weight: 600;
  margin: 0;
}
ul.initinery-detail-box p.date {
  color: #8b85c2;
}
ul.initinery-detail-box .dot {
  height: 22px;
  width: 22px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
}
ul.initinery-detail-box .name-hotel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1pc;
}
ul.initinery-detail-box .name-hotel button.btn {
  background: #f5f3fb !important;
  color: #8b85c2 !important;
}
ul.initinery-detail-box .box {
  background: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.062745098);
  padding: 1pc 2pc;
  border-radius: 12px;
}
ul.initinery-detail-box .activity {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
ul.initinery-detail-box .activity h5 {
  margin: 0;
}
ul.initinery-detail-box .inclusion-exclusion {
  display: flex;
  gap: 7pc;
}
ul.initinery-detail-box .inclusion-exclusion li {
  font-size: 13px;
  color: #797979;
  margin: 0;
}
ul.initinery-detail-box .inclusion-exclusion li .icon {
  font-size: 17px;
}

.itinerary-flight ul.flight-filter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  -moz-column-gap: 20px;
       column-gap: 20px;
  row-gap: 10px;
  margin-bottom: 1pc;
}
.itinerary-flight ul.flight-filter .sort-by__value-container {
  width: auto;
}
.itinerary-flight .select-date-origin {
  align-items: center;
}
.itinerary-flight .select-date-origin label {
  color: #000;
}
.itinerary-flight .select-date-origin .date-picker-module .react-daterange-picker {
  width: 100%;
}
.itinerary-flight .select-date-origin .date-picker-module .react-daterange-picker__wrapper {
  border: 1px solid #efeded;
  padding: 10px 10px;
  border-radius: 5px;
  background-color: #f8f8f8;
  width: 100%;
}
.itinerary-flight .select-date-origin .date-picker-module .react-daterange-picker__inputGroup:not(:first-child) {
  text-align: right;
}

.departing-flights .heading {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5pc;
}
.departing-flights .box {
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1294117647);
  border-radius: 10px;
  padding: 1pc;
  margin: 1pc 0;
}
.departing-flights .box .inner-box {
  display: flex;
  gap: 15px;
  align-items: center;
}
.departing-flights .box .inner-box .down-arrow {
  font-size: 40px;
  transition: all 0.5s;
  color: #000;
}
.departing-flights .box .inner-box.active .down-arrow {
  color: #ffc107;
  transform: scaleY(-1);
  transition: all 0.5s;
}
.departing-flights .box .left .image {
  height: 100px;
  width: 100px;
  border-radius: 10px;
}
.departing-flights .box .right {
  justify-content: space-around;
  display: flex;
  row-gap: 15px;
  -moz-column-gap: 15px;
       column-gap: 15px;
  align-items: center;
  flex-wrap: wrap;
}
.departing-flights .box .outer-box {
  border-top: 1px solid #ddd;
  margin-top: 1pc;
  padding: 1pc 2pc 0;
}
.departing-flights .box .outer-box ul li {
  display: flex;
  gap: 15px;
  position: relative;
  justify-content: flex-start;
}
.departing-flights .box .outer-box ul li .time {
  width: 40px;
}
.departing-flights .box .outer-box ul li:not(:last-child):before {
  content: "";
  position: absolute;
  left: 3.7pc;
  top: 0;
  width: 1px;
  height: 100%;
  background: #ffc107;
}
.departing-flights .box .outer-box ul li .dot {
  height: 10px;
  width: 10px;
  background: #ffc107;
  border-radius: 50%;
}
.departing-flights .box .outer-box p.travel-time {
  border: 1px solid #ddd;
  border-width: 1px 0;
  padding: 10px 0;
  margin: 10px 0;
}
.departing-flights .box .transfer-outer P {
  margin: 0;
}
.departing-flights .box .transfer-outer ul.location {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #eee;
  border-width: 1px 0;
  padding: 10px 0;
}
.departing-flights .box .transfer-outer ul.location li {
  display: flex;
  align-items: center;
  gap: 10px;
}
.departing-flights .box .transfer-outer ul.location li h6,
.departing-flights .box .transfer-outer ul.location li h5 {
  margin: 0;
}
.departing-flights .box .transfer-outer ul.price {
  border: 1px solid #eee;
  margin: 1pc 0;
}
.departing-flights .box .transfer-outer ul.price li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 1pc;
  line-height: normal;
}
.departing-flights .box .transfer-outer ul.price li span {
  color: #000;
  font-weight: 500;
}
.departing-flights .box .transfer-outer ul.price li:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.itinery-activity .box {
  position: relative;
  border-radius: 15px;
}
.itinery-activity .box:hover img {
  transform: scale(1.3);
}
.itinery-activity .box .image {
  height: 300px;
  border-radius: inherit;
}
.itinery-activity .box .image img {
  height: 100%;
  width: 100%;
}
.itinery-activity .box .content {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1pc;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 57%, rgba(9, 9, 9, 0.8) 100%);
  border-radius: inherit;
}
.itinery-activity .box .content button.red-bg {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 40px;
  height: 30px;
  width: 30px;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 10px;
}
.itinery-activity .box .content h5,
.itinery-activity .box .content p {
  color: #fff;
}
.itinery-activity .box .content h5 {
  margin-bottom: 0;
}
.itinery-activity .box .content .css-1c99szj-MuiRating-icon {
  color: #fff;
}

.career-form .form label {
  color: #000;
}

.form .sort-by__value-container {
  width: auto;
  padding: 5px 1pc;
}
.form .sort-by__control {
  height: auto;
  border-color: #efeded !important;
  background: #ffffff;
  border-radius: 5px;
}

.raise-ticket-modal p {
  margin-bottom: 0;
  color: #000;
}

.pdfbtn {
  color: #888888;
  font-weight: 700;
}

.modalcustom p.price {
  margin-bottom: 20px;
}
.modalcustom .packages-inner-right {
  padding: 3pc 2pc;
}
.modalcustom .packages-inner-left img {
  height: 285px;
}
.modalcustom h4 {
  font-size: 22px;
}
.modalcustom .modal-header {
  padding: 0;
  border: none;
}
.modalcustom .btn-close {
  position: absolute;
  top: 7px;
  right: 13px;
  z-index: 8856;
}
.modalcustom ul.offer img {
  height: 20px;
}

.gallerycardbox .modal-header {
  border-bottom: none;
}

.imgradius img {
  border-radius: 10px;
}

.react-calendar__tile--now {
  background: #d31a1a !important;
  color: #fff !important;
}

html {
  scroll-behavior: smooth;
}

.outlineButton {
  outline: none;
  border: none;
  background-color: white;
  border-radius: 10px;
  padding: 10px 25px;
  border: solid 1px rgb(123, 123, 123);
  color: rgb(123, 123, 123);
}

.active_flight {
  background-color: #fff;
  padding: 50px 0;
}

.filght_card ul {
  display: inline-flex;
  gap: 10px;
}

.filght_card li a {
  border: 1px solid #d9d9d9;
  padding: 12px 17px;
  border-radius: 100px;
  color: #888888;
}

.filght_card li a.active {
  box-shadow: inset 0px 2px 10px rgba(0, 0, 0, 0.06);
  border-radius: 22px;
  padding: 10px 12px;
  border-radius: 100px;
  color: #ff5a5a;
  font-weight: 700;
}

.active_flight .btn-book {
  background-color: #ff5a5a;
  padding: 10px 15px;
  border-radius: 5px;
  color: #fff;
  font-weight: 700;
}

.table_headermain img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.table_headermain {
  background-color: #e9e9e9;
  padding: 14px 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  align-items: center;
}

.table_headermain p {
  margin-left: 16px;
}

.table_tpading p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
}

.table_tpading h6 {
  font-weight: 400;
  font-size: 14px;
  color: #888888;
}

.table_tpading tbody td {
  color: #888888;
  font-size: 15px;
  font-weight: 400;
}

.filter_card_area {
  border: 0.5px solid #d9d9d9;
  padding: 30px 23px;
  border-radius: 22px;
  position: relative;
  margin-bottom: 10px;
  margin-right: 20px;
  background-color: #fff;
}

.left_min-card img {
  width: 50px;
  height: 50px;
  border-radius: 10%;
}

.min-headr {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.left_min-card {
  display: flex;
  align-items: end;
}

.left_min-card p {
  font-size: 19px;
  color: #3d3d3d;
  margin-left: 9px;
  line-height: 1;
}

.right_min-card h4 {
  color: #ff5050;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
}

.min_body_filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.min_body_filter p {
  margin-bottom: 0;
}

.rocket_img img {
  width: 60%;
}

.c_bodyfilter {
  text-align: center;
}

.l_bodyfilter .time_filter,
.c_bodyfilter .hourse,
.r_bodyfilter .time_filter {
  color: #000;
  font-size: 15px;
  font-weight: 500;
}

.docureiton_filter,
.r_bodyfilter .docureiton_filter {
  color: #3d3d3d;
  font-size: 14px;
  font-weight: 500;
}

.c_bodyfilter .non_stoop {
  color: #c4cdd5;
  font-size: 13px;
  font-weight: 500;
}

.icon_absoli {
  position: absolute;
  top: 10px;
  right: 15px;
}

.heigh_filter_section {
  height: 90vh;
  overflow: auto;
}

.heigh_filter_section::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.heigh_filter_section::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #faeded;
}

.heigh_filter_section::-webkit-scrollbar-thumb {
  background: #ff5050;
  border-radius: 10px;
}

.border_categoru {
  border: 0.5px solid #d9d9d9;
  padding: 30px 30px;
  border-radius: 10px;
  background-color: #fff;
}

.newceckout_top {
  background-image: url("../images/travelling_chekcout.png");
  padding: 60px 35px;
  background-repeat: no-repeat;
  background-position: center;
  height: 535px;
  background-size: cover;
  display: flex;
  align-items: center;
}

.bg_trapsprent {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 30px;
  min-height: 299px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bg_trapsprent h3 {
  font-size: 3pc;
  color: #fff;
  font-family: "Lexend";
  font-weight: 400;
}

.bg_trapsprent p {
  color: #fff;
  font-size: 23px;
  font-weight: 200;
}

.flight_details {
  padding: 41px 0;
}

.details_ldt h5 {
  color: #ff5050;
  font-size: 23px;
  font-weight: 600;
  position: relative;
}

.details_ldt h5::after {
  position: absolute;
  bottom: -6px;
  background-color: #ff5050;
  width: 200px;
  content: "";
  left: 0;
  right: 0;
  margin: auto;
  height: 2px;
}

.card_fligjaplly {
  background: #ffffff;
  box-shadow: 0px 2.28988px 11.4494px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  padding: 40px;
}

.list_updonw ul {
  display: inline-flex;
  gap: 10px;
}

.list_updonw li {
  color: #000;
  font-weight: 500;
  font-size: 20px;
}

.right_pp p {
  color: #ff5050;
  font-size: 17px;
  font-weight: 500;
}

.list_updonw .lightfont li:first-child {
  font-size: 12px;
  font-weight: 400;
}

.list_updonw .lightfont li:last-child {
  font-weight: 300;
  font-size: 12px;
}

.left_ari h4 {
  font-size: 14px;
  font-weight: 400;
}

.left_ari h4 img {
  width: 3%;
}

.right_sale h3 {
  font-size: 14px;
  font-weight: 400;
}

.right_sale h3 span {
  color: #ff5050;
}

.bgtable_chekcout {
  background-color: #f0f0f0;
  padding: 20px 23px;
  border-radius: 4px;
}

.bgtable_chekcout .table {
  margin-bottom: 0;
}

.summery_scio h4 {
  font-size: 20px;
  margin-bottom: 0;
}

.borderfare_summery {
  border: 0.5px solid #d9d9d9;
  border-radius: 10px;
  padding: 18px;
}

.borderfare_summery h3 {
  font-size: 20px;
  margin-bottom: 0;
  padding: 15px 0;
}

.brder_round {
  border: 0.5px solid #d9d9d9;
  border-radius: 10px;
  padding: 18px 17px;
}

.brder_round h5 {
  color: #888888;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 15px;
}

.taotal_amount h5 {
  margin-top: 10px;
  color: #4d4d4d;
}

.import_lnfo_list h3 {
  font-size: 23px;
  margin-bottom: 19px;
}

.import_lnfo_list ul {
  padding-left: 15px;
}

.import_lnfo_list li {
  list-style: disc;
  margin-bottom: 5px;
}

.bg_entername {
  background-color: #ffedd3;
  margin-bottom: 0;
  padding: 5px 0;
}

.input_text_contgrol .form-control {
  height: 60px;
}

.input_text_contgrol .from-group {
  position: relative;
}

.input_text_contgrol .from-group label {
  position: absolute;
  top: -14px;
  left: 10px;
  background-color: #fff;
  padding: 0px 16px;
  z-index: 99;
}

.input_text_contgrol .form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.css-319lph-ValueContainer {
  height: 58px;
}

.payment_info ul {
  padding: 0;
  margin: 0;
  display: inline-flex;
  gap: 49px;
  text-align: center;
}

.payment_info ul li img {
  width: 22%;
  margin-right: 10px;
}

.payment_info ul li.active {
  border-bottom: 2px solid #ff5050;
  color: #ff5050;
}

.payment_textinput .form-control {
  height: 60px;
}

.payment_textinput .from-group {
  position: relative;
}

.payment_textinput .from-group label {
  position: absolute;
  top: -14px;
  left: 10px;
  background-color: #fff;
  padding: 0px 16px;
  z-index: 99;
}

.payment_textinput .form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.secure_p p {
  color: #212b36;
  font-weight: 500;
}

.btn.btn-paynew {
  background-color: #ff5050 !important;
  color: #fff !important;
  padding: 7px 21px;
}

.tickets_point h3 {
  color: #919eab;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 0;
  padding: 15px 0;
}

.tickets_point p {
  color: #212b36;
  font-weight: 400;
  font-size: 18.3191px;
}

.outlineButton:hover {
  border: solid 1px rgb(255, 80, 80);
  color: rgb(255, 80, 80);
}

body {
  font-family: "Lexend", sans-serif;
  color: #797979;
  line-height: 1.7em;
  font-weight: 400;
  background: #fff;
  overflow-x: hidden;
}

main {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.ptb-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.px-4pc {
  padding-left: 4pc;
  padding-right: 4pc;
}

.mx-4pc {
  margin-left: 4pc;
  margin-right: 4pc;
}

.bg-fix {
  background-attachment: fixed;
}

.btn {
  border-radius: 0.375rem;
}
.btn:focus {
  box-shadow: none;
}
.btn-custom {
  font-weight: 500;
  --bs-btn-padding-x: 1pc;
}
.btn.with-icon {
  display: flex;
  align-items: center;
  gap: 10px;
}
.btn.red-bg {
  color: #fff;
}
.btn.red-bg:first-child:active {
  color: #fff !important;
  border-color: #FF5050;
}
.btn.red-bg:active {
  color: #fff !important;
  border-color: #FF5050;
}

.lexend {
  font-family: "Lexend", sans-serif !important;
}

.red {
  color: #FF5050 !important;
}

.red-bg {
  background-color: #FF5050 !important;
}

.green {
  color: #068A4F !important;
}

.green-bg {
  background-color: #068A4F !important;
}

.gray-bg {
  background-color: #f3f4f8 !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.list-circle li {
  list-style-type: circle;
  list-style-position: inside;
}

.flex-1 {
  flex: 1;
}

button:focus-visible {
  outline: 0;
}

.title-section .heading {
  line-height: 50px;
  text-transform: capitalize;
  font-weight: 400;
}
.title-section .heading + p {
  font-size: 20px;
  line-height: 40px;
}
.title-section .heading.bottom-line {
  position: relative;
  padding-bottom: 1pc;
}
.title-section .heading.bottom-line:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 50px;
  background-color: #FF5050;
}
.title-section .sub {
  width: -moz-max-content;
  width: max-content;
  position: relative;
  letter-spacing: 5px;
}
.title-section.bg {
  position: relative;
  width: -moz-max-content;
  width: max-content;
  padding: 1pc 0 1pc;
}
.title-section.bg:before {
  background-image: url("../images/decor/pattern4.png");
  background-repeat: no-repeat;
  background-position: center;
  content: "";
  position: absolute;
  height: 100%;
  width: 60%;
  top: 0;
  left: -1pc;
  z-index: 0;
  opacity: 0.5;
}
.title-section.bg .heading {
  line-height: normal;
  margin-bottom: 0;
}
.title-section.bg .heading,
.title-section.bg h1,
.title-section.bg h2,
.title-section.bg h3,
.title-section.bg h4,
.title-section.bg h5,
.title-section.bg h6,
.title-section.bg p,
.title-section.bg a {
  position: relative;
  z-index: 4;
}
.title-section .bg-2 {
  position: relative;
  width: -moz-max-content;
  width: max-content;
  padding: 0 3pc 3pc;
}
.title-section .bg-2:before {
  background-image: url("../images/decor/landing-plane-img.png");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  right: -2pc;
  z-index: 0;
}
.title-section h6 {
  font-size: 20px;
  line-height: 28px;
  text-transform: capitalize;
  margin: 0;
}

details:focus-visible {
  outline: 0;
}

summary:focus-visible {
  outline: 0;
}

p {
  line-height: 1.8em;
}
p:last-child {
  margin-bottom: 0;
}

.img-cover {
  -o-object-fit: cover;
     object-fit: cover;
}

.img-contain {
  -o-object-fit: contain;
     object-fit: contain;
}

.img-position-top {
  -o-object-position: top;
     object-position: top;
}

.img-fill {
  -o-object-fit: fill;
     object-fit: fill;
}

a {
  text-decoration: none;
  color: #000;
  transition: all 0.5s;
}
a:hover {
  transition: all 0.5s;
  color: #FF5050;
}

ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
}
ul:not(.row) li:last-child {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
}

.btn-sm {
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.main-logo {
  height: 50px;
  width: auto;
  -o-object-fit: contain;
     object-fit: contain;
  transition: all 0.5s;
}

.translateX {
  transform: translateX(0) !important;
}

.pointer {
  cursor: pointer;
}

.overlay {
  position: relative;
}
.overlay:before {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  content: "";
  z-index: 66;
  pointer-events: none;
}

#scrollUp {
  position: fixed;
  right: 1pc;
  bottom: 1pc;
  opacity: 0;
}
#scrollUp .scroll-inner {
  height: 45px;
  width: 45px;
  background: #f16331;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #fff;
  font-size: 22px;
  cursor: pointer;
}

.image {
  overflow: hidden;
}
.image img {
  border-radius: inherit;
  transition: all 0.5s ease-in-out;
}
.image:not(.not-hover) img:hover {
  transform: scale(1.2);
  transition: all 0.5s ease-in-out;
}

#call-us {
  position: fixed;
  right: 2pc;
  bottom: 1pc;
  filter: drop-shadow(0px 0px 6px gray);
  z-index: 9999;
  opacity: 0;
}
#call-us:before {
  content: "";
  position: absolute;
  border: 2px solid #fff;
  bottom: 0;
  right: 0;
  transform: translate(-2px, -2px);
  height: 15px;
  width: 15px;
  background: #128c7e;
  z-index: 0;
}
#call-us .call-us-inner {
  height: 55px;
  width: 55px;
  background: #128c7e;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  font-size: 30px;
  transform: scaleX(-1);
}
#call-us .call-us-inner i {
  animation: phone 1.5s linear 0s infinite alternate;
}

img {
  -o-object-fit: cover;
     object-fit: cover;
}

.line {
  width: 70px;
  background: #fff;
  height: 1px;
}

input:focus-visible {
  outline: 0;
}

.form.filled-input .form-control,
.form.filled-input .select2-selection--single {
  background-color: rgba(243, 244, 248, 0.6705882353) !important;
}
.form [class*=col-] {
  margin-bottom: 10px;
}
.form [class*=col-]:last-child {
  margin-bottom: 0;
}
.form .form-check-label {
  flex: 1;
  color: #000;
  display: flex;
  align-items: center;
  gap: 11px;
  cursor: pointer;
}
.form .form-check:not(.form-check-inline) {
  display: flex;
  gap: 10px;
  align-items: center;
}
.form .form-check.form-check-inline .form-check-input {
  margin-right: 10px;
}
.form .form-check input[type=checkbox] {
  height: 20px;
  width: 20px;
  border: 1px solid #d0d0d0;
  border-radius: 0;
  position: relative;
  background-color: #fff;
  cursor: pointer;
}
.form .form-check input[type=checkbox].form-check-input:focus {
  border-color: rgba(0, 0, 0, 0.2509803922);
  box-shadow: none;
}
.form .form-check input[type=checkbox]:checked {
  background-color: #068A4F;
  border-color: #068A4F;
}
.form .form-check input[type=radio] {
  cursor: pointer;
}
.form .form-check input[type=radio]:checked {
  background-color: #FF5050;
  border-color: #FF5050;
}
.form .form-check input[type=radio]:focus {
  border-color: #FF5050;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 80, 80, 0.2509803922);
}
.form .select2-container--default {
  width: 100% !important;
}
.form .select2-container--default .select2-selection--single {
  height: 40px !important;
  border-radius: 0;
  width: 100%;
  padding: 0.375rem 0.75rem;
  background-color: #fff;
  border: 1px solid #ced4da !important;
}
.form .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 40px !important;
}
.form .form-control,
.form .form-select {
  border-color: #efeded;
  padding: 12px 10px;
  background: #f8f8f8;
}
.form .form-control:focus,
.form .form-select:focus {
  box-shadow: none;
  border-color: #FF5050;
  background-color: #ffefef;
  transition: all 0.5s;
}
.form .form-control:focus::-moz-placeholder, .form .form-select:focus::-moz-placeholder {
  opacity: 0;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.form .form-control:focus::placeholder,
.form .form-select:focus::placeholder {
  opacity: 0;
  transition: all 0.5s;
}
.form .form-control::-moz-placeholder, .form .form-select::-moz-placeholder {
  opacity: 1;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  text-transform: capitalize;
}
.form .form-control::placeholder,
.form .form-select::placeholder {
  opacity: 1;
  transition: all 0.5s;
  text-transform: capitalize;
}
.form label {
  margin: 0;
  color: #838383;
  line-height: 20px;
  width: 100%;
  font-size: 15px;
}
.form label.small {
  font-size: 0.875em;
}

.line-height-normal {
  line-height: normal;
}

.swiper-button-prev, .swiper-button-next {
  background: #fff;
  height: 55px !important;
  width: 55px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50%;
  transition: all 0.5s;
}
.swiper-button-prev:hover, .swiper-button-next:hover {
  background-color: #FF5050;
  transition: all 0.5s;
}
.swiper-button-prev:hover:after, .swiper-button-next:hover:after {
  color: #fff;
}
.swiper-button-prev:after, .swiper-button-next:after {
  font-size: 24px !important;
  font-weight: 600;
  color: #000;
}
.swiper .swiper-pagination-bullet-active {
  background-color: #FF5050;
}

.col-xxl-auto {
  width: 20%;
}

.flight_page_filter {
  background-color: #ff5a5a;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
}

.flight_page_destination_color {
  color: #ff5a5a;
}

@media only screen and (max-width: 576px) {
  header .navbar {
    margin: 10px 0 !important;
  }
  header .navbar a.btn.red-bg {
    font-size: 12px;
    padding: 5px 10px;
  }
  .footer {
    margin: 0 1pc !important;
  }
  .best-off-box .image {
    height: 200px;
  }
  .best-off-box .content {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, 0.6392156863) 100%);
  }
  .best-off-box .content p {
    font-size: 14px;
    line-height: normal;
  }
  .best-off-box .content h4 {
    font-size: 18px;
  }
  .best-off-box.trending-destination-left .image {
    height: 175px;
  }
  .best-off-box.trending-destination-right .image {
    height: 200px;
  }
  .best-off-box.trending-destination-left .content h4, .best-off-box.trending-destination-right .content h4 {
    font-size: 14px;
  }
  .best-off-box.most-viewed-right .image, .best-off-box.most-viewed-left .image {
    height: 175px;
  }
  .best-off-box.most-viewed-right .content h5, .best-off-box.most-viewed-left .content h5 {
    font-size: 14px;
  }
  .main-logo {
    height: 35px;
  }
  .timeline .dot {
    height: 15px;
    width: 15px;
  }
  .timeline li {
    margin-right: 35px;
  }
  .timeline li:not(:last-child) .empty-line,
  .timeline li:not(:last-child) .full-filled {
    width: 35px;
  }
  .timeline li.half:last-child .half-filled {
    left: -23px;
  }
  .timeline li.half .dot {
    height: 20px;
    width: 20px;
  }
  .timeline li.half .dot:before {
    height: 10px;
    width: 10px;
  }
  .timeline li.half .half-filled {
    width: 40px;
  }
  .timeline li.half .half-filled img {
    width: 25px;
  }
  .questions {
    padding: 2pc 0pc 4pc;
    background-size: 9pc;
  }
  .questions .title-section .heading {
    line-height: 30px;
  }
  .questions-inner {
    margin: 2pc 0 1pc;
  }
  .questions .search-bar {
    padding: 10px;
  }
  .questions .search-bar .btn.red-bg {
    font-size: 14px;
    gap: 5px;
    padding: 5px 10px;
    background: none !important;
    padding: 0 !important;
  }
  .questions .search-bar .btn.red-bg .icon {
    color: #ff5050;
    font-size: 22px;
    display: flex;
    align-items: center;
  }
  .questions .search-bar .btn.red-bg span {
    display: none;
  }
  .questions .destination-list .best-off-box {
    width: calc(50% - 15px);
  }
  .questions .destination-list .best-off-box .image {
    height: 165px;
  }
  .questions .destination-list .best-off-box .content p {
    display: none;
  }
  .questions .destination-list .best-off-box .content h4 {
    font-size: 17px;
  }
  .ques-footer button.btn {
    font-size: 14px;
  }
  ul.travel-month li {
    width: calc(33.33% - 10px);
    margin: 0 10px 10px 0px !important;
    padding: 1pc;
  }
  ul.travel-month li > p {
    font-size: 9px;
  }
  ul.travel-month li img {
    height: 25px;
    margin: 5px 0;
  }
  ul.travel-month li > h5 {
    font-size: 13px;
  }
  .travel-purpose {
    justify-content: flex-start;
  }
  .travel-purpose li {
    width: calc(50% - 15px);
    margin: 0 15px 15px 0px;
  }
  .travel-purpose li img {
    height: 100px;
  }
  .travel-purpose li h5 {
    font-size: 12px;
    margin-top: 10px;
  }
  .select-age > .d-flex {
    -moz-column-gap: 15px;
         column-gap: 15px;
    row-gap: 15px;
  }
  .select-age label {
    gap: 10px;
  }
  .select-age label h5 {
    font-size: 16px;
  }
  .select-age-box {
    padding: 8px 10px;
  }
  .select-age-box .form-check-input {
    height: 25px;
    width: 25px;
  }
  .px-4pc {
    padding-left: 0pc !important;
    padding-right: 0pc !important;
  }
  .talk-to-expert {
    min-width: 90vw;
  }
  .talk-to-expert .form .form-control {
    padding: 5px 10px;
  }
  .home-trip-tabs .inner {
    margin: 0 1pc;
  }
  .main-slider-content .title-section {
    top: 50%;
    width: 90%;
  }
  .whatsapp {
    top: auto;
    bottom: 6pc;
    transform: none;
  }
  .adventure-box h5 {
    font-size: 15px;
  }
  .adventure-box .image img {
    height: 95px;
    width: 96px;
  }
  .testimonials-box .content p {
    line-height: normal;
    font-size: 14px;
  }
  .footer {
    padding: 2pc 10px !important;
  }
  .footer-inner p {
    font-size: 13px;
    line-height: normal;
  }
  .footer .logo {
    margin-bottom: 0;
  }
  .footer ul.social-links {
    margin-top: 5px;
  }
  .footer ul.social-links img {
    height: 20px;
  }
  .footer ul.links li {
    margin-bottom: 0px;
    font-size: 14px;
  }
  .footer ul.links li a {
    white-space: nowrap;
  }
  .footer ul.links.details li {
    margin-bottom: 3px;
  }
  .footer ul.links.details li input.form-control {
    padding: 10px 1pc;
    font-size: 13px;
  }
  .footer .icon img {
    height: 22px;
  }
  .itinerary-top-banner .content h1 {
    font-size: 20px;
    margin-bottom: 1pc;
  }
  .itinerary-top-banner .content ul li {
    font-size: 10px;
    font-weight: 400;
    line-height: normal;
    padding: 7px 10px;
  }
  ul.filter-1 li,
  ul.filter-1 .btn {
    font-size: 12px;
  }
  ul.filter-1 li {
    padding: 0 5px;
  }
  ul.filter-2 {
    overflow-x: scroll;
    padding: 10px;
    justify-content: initial;
  }
  ul.filter-2 li {
    font-size: 12px;
    padding: 5px 1pc;
    line-height: normal;
  }
  .hotel-img-slider .heading,
  .flight-info .heading {
    margin-bottom: 1pc;
    padding-bottom: 5px;
  }
  .hotel-img-slider .heading h4,
  .flight-info .heading h4 {
    font-size: 16px;
  }
  .flight-info .box .content {
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }
  .flight-info .box .content ul {
    flex-direction: column;
  }
  .flight-info .box .content ul img.plane-img {
    width: 30px;
  }
  .flight-info .box .content ul > li {
    text-align: center;
  }
  .flight-info .box .content ul > li p {
    font-size: 12px;
  }
  .flight-info .box .content ul > li:last-child {
    border-left: 0;
    border-top: 1px solid #797979;
    padding: 10px 0 0;
  }
  .flight-info .box .content ul:not(:last-child) {
    border: 0;
    margin: 0;
    padding: 0;
  }
  .edit-option li {
    font-size: 12px;
  }
  ul.initinery-detail-box {
    padding: 0;
    overflow: hidden;
  }
  ul.initinery-detail-box p {
    font-size: 12px;
    line-height: normal;
  }
  ul.initinery-detail-box .name-hotel {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  ul.initinery-detail-box .name-hotel button.btn {
    font-size: 12px;
    padding: 5px 10px;
  }
  ul.initinery-detail-box > li {
    gap: 10px;
  }
  ul.initinery-detail-box > li:not(:last-child):before {
    left: 4pc;
  }
  ul.initinery-detail-box .dot {
    height: 13px;
    width: 13px;
  }
  ul.initinery-detail-box p.date {
    font-size: 9px;
  }
  ul.initinery-detail-box .activity h5 {
    font-size: 15px;
  }
  ul.initinery-detail-box .activity i.small {
    font-size: 12px;
  }
  ul.initinery-detail-box .activity ul.edit-option {
    flex-direction: column;
    gap: 0px;
  }
  ul.initinery-detail-box .activity ul.edit-option li {
    line-height: normal;
  }
  ul.initinery-detail-box .box {
    padding: 10px;
  }
  ul.initinery-detail-box .inclusion-exclusion {
    flex-direction: column;
    gap: 10px;
  }
  ul.initinery-detail-box .inclusion-exclusion h6 {
    font-size: 14px;
  }
  ul.initinery-detail-box .inclusion-exclusion li {
    font-size: 11px;
    align-items: center;
    line-height: normal;
    margin-bottom: 5px;
  }
  ul.initinery-detail-box .inclusion-exclusion li .icon {
    font-size: 12px;
    display: flex;
  }
  .itinerary-flight .select-date-origin .btn.btn-lg {
    font-size: 14px;
    padding: 6px 1pc;
    gap: 5px;
  }
  .departing-flights:not(.transfer-flight) .box .inner-box.active {
    align-items: center;
  }
  .departing-flights:not(.transfer-flight) .box .inner-box.active ul.right {
    flex-direction: column;
  }
  .departing-flights:not(.transfer-flight) .box .inner-box.active ul.right li {
    width: 100%;
  }
  .departing-flights .heading {
    flex-direction: column;
    align-items: flex-start;
  }
  .departing-flights .box .left .image {
    height: 70px;
    width: 70px;
  }
  .departing-flights .box .right {
    justify-content: flex-start;
    row-gap: 5px;
    -moz-column-gap: 10px;
         column-gap: 10px;
    align-items: flex-start;
    position: relative;
  }
  .departing-flights .box .right h5 {
    font-size: 15px;
    margin-bottom: 0;
  }
  .departing-flights .box .right p {
    font-size: 12px;
  }
  .departing-flights .box .right li {
    width: calc(50% - 10px);
  }
  .departing-flights .box .right li:last-child {
    position: absolute;
    top: -1pc;
    right: 0;
    width: auto !important;
  }
  .departing-flights .box .right li:last-child .icon {
    font-size: 22px;
  }
  .departing-flights .box .right button.btn {
    font-size: 12px;
    padding: 3px 7px !important;
  }
  .departing-flights .box .inner-box {
    align-items: flex-start;
    gap: 10px;
  }
  .departing-flights .box .outer-box ul li {
    flex-direction: column;
    gap: 0px;
  }
  .departing-flights .box .outer-box ul li .dot, .departing-flights .box .outer-box ul li:not(:last-child):before {
    display: none;
  }
  .departing-flights .box .outer-box ul li .time {
    color: #FF5050;
  }
  .departing-flights .box .outer-box ul li i.small {
    line-height: normal;
  }
  .departing-flights .box .outer-box ul li:first-child {
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .departing-flights .box .outer-box ul li p.travel-time {
    line-height: normal;
  }
  .departing-flights .box .transfer-outer p,
  .departing-flights .box .transfer-outer li {
    font-size: 14px;
  }
  .departing-flights .box .transfer-outer ul.location {
    flex-direction: column;
    row-gap: 5px;
    margin: 10px 0;
  }
  .departing-flights .box .transfer-outer ul.location h5,
  .departing-flights .box .transfer-outer ul.location h6 {
    font-size: 14px;
  }
  .title-section.bg {
    width: auto;
    margin-bottom: 10px !important;
  }
  .title-section .heading + p {
    line-height: normal;
    margin-top: 10px;
  }
  .testimonials-style-2-box .image {
    height: 275px;
    margin-bottom: 1pc;
  }
  .testimonials-style-2-box .right h4.tour-to,
  .testimonials-style-2-box .right .content {
    margin: 10px 0;
  }
  .testimonials-style-2-box .right .content p {
    font-size: 15px;
  }
  .testimonials-style-2 .swiper-button-prev,
  .testimonials-style-2 .swiper-button-next {
    display: none !important;
  }
  .trending-blog .content {
    padding: 1pc;
  }
  .trending-blog .image {
    height: 250px;
    border-radius: 0;
  }
  .trending-blog .row {
    border-radius: 0;
  }
  .page-banner-2 .image {
    margin: 0;
  }
  .page-banner-2 .image.left {
    height: 400px;
    margin: 0;
  }
  .page-banner-2 .image.right {
    margin-top: 6px;
    height: 75px;
  }
  .page-banner-2 .row.px-4pc {
    justify-content: center;
  }
  .trip-info ul {
    gap: 10px;
  }
  .trip-info ul li {
    width: calc(50% - 10px);
  }
  .trip-info ul li .icon {
    font-size: 20px;
  }
  .trip-info ul li h5 {
    font-size: 16px;
    align-items: flex-start;
  }
  .trip-info ul li p {
    font-size: 14px;
    line-height: normal;
  }
  ul.filter-tabs {
    flex-wrap: nowrap;
    overflow-x: scroll;
    justify-content: initial;
    padding-bottom: 2px !important;
  }
  ul.filter-tabs::-webkit-scrollbar {
    width: 0px !important;
    height: 0 !important;
  }
  ul.filter-tabs .btn {
    font-size: 14px;
    padding: 8px 15px;
  }
  ul.trip-includes {
    gap: 16px;
    justify-content: space-around;
  }
  ul.trip-includes li .icon {
    height: 50px;
    width: 50px;
    padding: 10px;
    border-radius: 5px;
  }
  ul.trip-includes li h5 {
    font-size: 12px;
    margin-top: 10px;
  }
  .trip-price-detail {
    padding: 0pc 0 1pc;
  }
  ul.trip-itinerary li span {
    padding: 0px 7px;
    font-size: 12px;
  }
  ul.trip-itinerary li .title {
    align-items: flex-start;
  }
  ul.trip-itinerary li .title > .d-flex {
    align-items: flex-start !important;
  }
  ul.trip-itinerary li p {
    line-height: normal;
  }
  .trip-pickup-drop ul {
    gap: 1pc;
  }
  .trip-pickup-drop ul .icon {
    font-size: 25px;
  }
  .trip-pickup-drop ul .line {
    width: 20px;
  }
  .trip-pickup-drop ul h5 {
    font-size: 16px;
  }
  .trip-pickup-drop ul p {
    line-height: normal;
    font-size: 14px;
  }
  .trip-overview ul.quick-info li {
    line-height: normal;
    align-items: flex-start;
  }
  .trip-overview h5 {
    margin-bottom: 5px;
  }
  .trip-all-detail h2.mb-4 {
    margin-bottom: 5px !important;
    font-size: 22px;
  }
  .sign-in {
    overflow-y: scroll;
  }
  .sign-in .right {
    padding: 1pc;
  }
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
  .best-off-box .image {
    height: 250px;
  }
  .best-off-box .image img,
  .best-off-box .image,
  .best-off-box .content {
    border-radius: 10px;
  }
  .best-off-box.trending-destination-left .image {
    height: 200px;
  }
  .best-off-box.trending-destination-right .image {
    height: 250px;
  }
  .best-off-box.most-viewed-right .image, .best-off-box.most-viewed-left .image {
    height: 200px;
  }
  .timeline .dot {
    height: 20px;
    width: 20px;
  }
  .timeline li {
    margin-right: 55px;
  }
  .timeline li:not(:last-child) .empty-line,
  .timeline li:not(:last-child) .full-filled {
    width: 55px;
  }
  .timeline li.half:last-child .half-filled {
    left: -23px;
  }
  .timeline li.half .dot {
    height: 25px;
    width: 25px;
  }
  .timeline li.half .dot:before {
    height: 13px;
    width: 13px;
  }
  .timeline li.half .half-filled {
    width: 60px;
  }
  .timeline li.half .half-filled img {
    width: 30px;
  }
  .questions {
    padding: 2pc 1pc 6pc 1pc;
  }
  .questions .title-section .heading {
    line-height: 40px;
  }
  .questions-inner {
    margin: 2pc 0;
  }
  .questions .search-bar .btn.red-bg {
    font-size: 15px;
  }
  .questions .destination-list .best-off-box {
    width: 170px;
  }
  .questions .destination-list .best-off-box .image {
    height: 195px;
  }
  .ques-footer button.btn {
    font-size: 17px;
  }
  ul.travel-month li {
    width: calc(33% - 25px);
    padding: 1pc;
  }
  .select-age-box .form-check-input {
    height: 35px;
    width: 35px;
  }
  .travel-purpose li {
    width: calc(50% - 25px);
  }
}
@media only screen and (max-width: 768px) {
  .main-slider-content .title-section .heading {
    font-size: 28px !important;
    margin: 0 !important;
  }
  .main-slider-content .title-section .heading + p {
    font-size: 16px !important;
  }
  .testimonials-box {
    min-height: auto;
  }
  .testimonials-box .image {
    margin: 1pc 0;
  }
  .testimonials-box .image img {
    height: 110px;
    width: 110px;
  }
  .main-slider-tabs {
    display: none;
  }
  .main-slider-content {
    padding: 15% 0 0;
    align-items: center;
  }
  .mb-80 {
    margin-bottom: 40px !important;
  }
  .mt-80 {
    margin-top: 40px !important;
  }
  .ptb-80 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .pt-80 {
    padding-top: 40px !important;
  }
  .pb-80 {
    padding-bottom: 40px !important;
  }
  .main-slider {
    height: 100vh;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .best-off-box.trending-destination-left .image {
    height: 265px;
  }
  .best-off-box.trending-destination-right .image {
    height: 300px;
  }
  .best-off-box.most-viewed-right .image {
    height: 300px;
  }
  .timeline li {
    margin-right: 90px;
  }
  .timeline li:not(:last-child) .empty-line,
  .timeline li:not(:last-child) .full-filled {
    width: 90px;
  }
  .timeline li.half:last-child .half-filled {
    left: -40px;
  }
  .timeline li.half .half-filled {
    width: 85px;
  }
  .timeline li.half .half-filled img {
    width: 35px;
  }
  .questions {
    padding: 2pc 1pc 6pc 1pc;
  }
  .questions .title-section .heading {
    line-height: 40px;
  }
  .questions-inner {
    margin: 2pc 0;
  }
  .questions .search-bar {
    margin-top: 2pc;
  }
  .questions .search-bar .btn.red-bg {
    font-size: 15px;
  }
  .questions .destination-list .best-off-box {
    width: calc(20% - 15px);
  }
  .questions .destination-list .best-off-box .content p {
    display: none;
  }
  .questions .destination-list .best-off-box .content h4 {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 1px;
  }
  .questions .destination-list .best-off-box .image {
    height: 170px;
  }
  .ques-footer button.btn {
    font-size: 17px;
  }
  ul.travel-month li {
    width: calc(25% - 25px);
    padding: 1pc;
  }
  .select-age-box {
    padding: 10px 1pc;
  }
  .select-age-box .form-check-input {
    height: 35px;
    width: 35px;
  }
  .travel-purpose li {
    width: calc(25% - 20px);
    margin: 0 20px 20px 0px;
  }
  .travel-purpose li img {
    height: 105px;
  }
  .travel-purpose li h5 {
    font-size: 16px;
  }
  .main-slider-content {
    padding-bottom: 20%;
  }
  .main-slider-tabs .btn.red-bg {
    font-size: 15px;
  }
  .main-slider-tabs ul.tabs {
    gap: 10px;
  }
  .main-slider-tabs ul.tabs button img {
    width: 30px;
  }
  .main-slider-tabs .tab-content .travel-info h5 {
    font-size: 17px;
    margin-bottom: 7px;
  }
  .main-slider-tabs .tab-content .travel-info > li {
    padding: 0 10px;
  }
  .main-slider-tabs .tab-content .travel-info > li p.text {
    line-height: normal;
  }
  .main-slider-tabs .tab-content .traveller {
    width: auto !important;
  }
  .main-slider-tabs .tab-content .traveller ul.number li {
    padding: 2px 10px;
  }
  .main-slider-tabs .tab-content .traveller ul.number.travel-class li {
    padding: 5px 10px;
  }
}
@media only screen and (max-width: 992px) {
  header .navbar .dropdown-menu {
    padding: 0;
    border-radius: 0;
    border: 0;
  }
  header.change-header .navbar .nav-link {
    color: #000000 !important;
  }
  .offcanvas-header .btn-close {
    background-color: #ffffff;
    border-radius: 0;
    filter: invert(1) saturate(0.5);
  }
  .offcanvas .navbar-nav {
    align-items: start;
    justify-content: start;
    gap: 0;
  }
  .offcanvas .navbar-nav li.nav-item {
    width: 100%;
  }
  .offcanvas .navbar-nav li.nav-item a {
    letter-spacing: 0ch;
    border-bottom: 1px solid #e3dede;
    font-weight: 400;
    padding: 10px 1pc;
  }
  .testimonials {
    padding: 2pc 2pc 1pc;
  }
  .awards {
    padding: 2pc;
  }
  .test-award-mid {
    height: 370px;
  }
  .questions .main-logo {
    height: 40px;
  }
}
@media only screen and (min-width: 992px) {
  header .navbar .dropdown-menu {
    display: block !important;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s;
    padding: 0;
    min-width: 210px;
    border-top: 4px solid #ff5355;
  }
  header .navbar .dropdown-menu li:not(:last-child) a.dropdown-item {
    border-bottom: 1px solid #ddd;
  }
  header .navbar .dropdown-item {
    padding: 10px 1pc;
  }
  header .navbar .dropdown-item:hover {
    background: rgba(255, 167, 167, 0.6588235294);
    color: #ff5355;
  }
  header .navbar .dropdown-toggle::after {
    display: none;
  }
  header .navbar li.nav-item.dropdown:hover ul.dropdown-menu {
    visibility: visible;
    opacity: 1;
    transition: all 0.5s;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .packages-left {
    width: 24%;
  }
  .best-off-box .image {
    height: 250px;
  }
  .best-off-box .image img,
  .best-off-box .image,
  .best-off-box .content {
    border-radius: 10px;
  }
  .best-off-box.most-viewed-right .image, .best-off-box.most-viewed-left .image {
    height: 250px;
  }
  .test-award-mid {
    height: 450px;
  }
  .questions {
    padding: 2pc 2pc 5pc 2pc;
  }
  .questions-inner {
    margin: 2pc 0 1pc;
  }
  .questions .destination-list .best-off-box {
    width: calc(20% - 15px);
  }
  .questions .destination-list .best-off-box .content p {
    display: none;
  }
  .questions .destination-list .best-off-box .content h4 {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 1px;
  }
  .questions .destination-list .best-off-box .image {
    height: 170px;
  }
  ul.travel-month li {
    width: calc(16% - 15px);
    padding: 1pc;
    margin: 0 15px 15px 0px !important;
  }
  ul.travel-month li p {
    font-size: 14px;
  }
  ul.travel-month li img {
    height: 30px;
    margin: 10px 0;
  }
  ul.travel-month li h5 {
    font-size: 16px;
  }
  .travel-purpose li {
    width: calc(25% - 20px);
    margin: 0 20px 20px 0px;
  }
  .travel-purpose li h5 {
    font-size: 16px;
  }
  .select-age-box {
    padding: 10px 1pc;
  }
  .select-age-box .form-check-input {
    height: 35px;
    width: 35px;
  }
}
@media only screen and (max-width: 1200px) {
  .packages-inner {
    padding: 0;
  }
  .packages-inner-left {
    border-radius: 10px 10px 0 0;
  }
  .packages-inner-left img {
    border-radius: inherit;
  }
  .packages-inner-right {
    text-align: center;
  }
  .packages-inner-right .heading {
    justify-content: center;
    margin: 0;
  }
  .packages-inner-right p.price {
    justify-content: center;
    line-height: normal;
  }
  .packages-inner-right ul.detail {
    justify-content: center;
  }
  .packages-inner-right .total-review,
  .packages-inner-right ul.offer,
  .packages-inner-right .itinarary {
    display: none !important;
  }
  .footer {
    margin: 0 3pc;
    padding-left: 1pc !important;
    padding-right: 1pc !important;
    padding: 3pc 0;
  }
  .footer h4.heading {
    font-size: 20px;
    margin-bottom: 10px;
  }
  header .navbar {
    margin: 10px 1pc;
  }
  .px-4pc {
    padding-left: 2pc;
    padding-right: 2pc;
  }
  .adventure .title-section {
    background: #fff !important;
    text-align: center;
    justify-content: center;
    height: auto;
    padding: 0;
    margin-bottom: 2pc;
  }
  .adventure .title-section h2.heading {
    color: #000 !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .packages-left {
    width: 22%;
  }
  .footer {
    margin: 0 3pc;
    padding-left: 2pc;
    padding-right: 2pc;
  }
  .best-off-box .image {
    height: 275px;
  }
  .best-off-box .image img,
  .best-off-box .image,
  .best-off-box .content {
    border-radius: 10px;
  }
  .best-off-box.most-viewed-right .image {
    height: 250px;
  }
  .test-award-mid {
    height: 450px;
  }
  .questions-inner {
    margin: 2pc 0;
  }
  .questions-inner .destination-list {
    margin: 0 3pc;
  }
  .questions-inner .destination-list .best-off-box .image {
    height: 200px;
  }
  ul.travel-month li {
    width: calc(16% - 15px);
    padding: 1pc;
    margin: 0 15px 15px 0px !important;
  }
  ul.travel-month li p {
    font-size: 14px;
  }
  ul.travel-month li img {
    height: 30px;
    margin: 10px 0;
  }
  ul.travel-month li h5 {
    font-size: 16px;
  }
  .travel-purpose li {
    width: calc(25% - 20px);
    margin: 0 20px 20px 0px;
  }
  .travel-purpose li h5 {
    font-size: 16px;
  }
  .select-age-box {
    padding: 10px 1pc;
  }
  .select-age-box .form-check-input {
    height: 35px;
    width: 35px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1700px) {
  .questions-inner {
    margin: 2pc 0;
  }
  .questions-inner .destination-list {
    margin: 0 6pc;
  }
  .footer {
    margin: 0 3pc;
  }
}
@media only screen and (min-width: 1400px) {
  ul.travel-purpose {
    margin: 0 8pc;
  }
}
@media only screen and (min-width: 1700px) {
  ul.travel-purpose {
    margin: 0 12pc;
  }
  .questions-inner .destination-list {
    margin: 0 14pc;
  }
  .main-slider-tabs {
    margin: 0 5pc;
  }
}/*# sourceMappingURL=main.css.map */