// =================================================================================================
@media only screen and (max-width: 576px) {
  header .navbar {
    margin: 10px 0 !important;
    a.btn.red-bg {
      font-size: 12px;
      padding: 5px 10px;
    }
  }
  .footer {
    margin: 0 1pc !important;
  }
  .best-off-box {
    .image {
      height: 200px;
    }
    .content {
      background: linear-gradient(180deg, #00000000 35%, #000000a3 100%);
      p {
        font-size: 14px;
        line-height: normal;
      }
      h4 {
        font-size: 18px;
      }
    }
    &.trending-destination {
      &-left .image {
        height: 175px;
      }
      &-right .image {
        height: 200px;
      }
      &-left,
      &-right {
        .content h4 {
          font-size: 14px;
        }
      }
    }
    &.most-viewed {
      &-right,
      &-left {
        .image {
          height: 175px;
        }
        .content h5 {
          font-size: 14px;
        }
      }
    }
  }
  .main-logo {
    height: 35px;
  }
  .timeline {
    .dot {
      height: 15px;
      width: 15px;
    }
    li {
      margin-right: 35px;
      &:not(:last-child) {
        .empty-line,
        .full-filled {
          width: 35px;
        }
      }
      &.half {
        &:last-child .half-filled {
          left: -23px;
        }
        .dot {
          height: 20px;
          width: 20px;
          &:before {
            height: 10px;
            width: 10px;
          }
        }
        .half-filled {
          width: 40px;
          img {
            width: 25px;
          }
        }
      }
    }
  }
  .questions {
    padding: 2pc 0pc 4pc;
    background-size: 9pc;
    .title-section .heading {
      line-height: 30px;
    }
    &-inner {
      margin: 2pc 0 1pc;
    }
    .search-bar {
      padding: 10px;
      .btn.red-bg {
        font-size: 14px;
        gap: 5px;
        padding: 5px 10px;
        background: none !important;
        padding: 0 !important;
        .icon {
          color: #ff5050;
          font-size: 22px;
          display: flex;
          align-items: center;
        }
        span {
          display: none;
        }
      }
    }
    .destination-list .best-off-box {
      width: calc(50% - 15px);
      .image {
        height: 165px;
      }
      .content {
        p {
          display: none;
        }
        h4 {
          font-size: 17px;
        }
      }
    }
  }
  .ques-footer button.btn {
    font-size: 14px;
  }
  ul.travel-month li {
    width: calc(33.33% - 10px);
    margin: 0 10px 10px 0px !important;
    padding: 1pc;
    & > p {
      font-size: 9px;
    }

    img {
      height: 25px;
      margin: 5px 0;
    }

    & > h5 {
      font-size: 13px;
    }
  }
  .travel-purpose {
    justify-content: flex-start;
    li {
      width: calc(50% - 15px);
      margin: 0 15px 15px 0px;
      img {
        height: 100px;
      }
      h5 {
        font-size: 12px;
        margin-top: 10px;
      }
    }
  }
  .select-age {
    & > .d-flex {
      column-gap: 15px;
      row-gap: 15px;
    }
    label {
      gap: 10px;
      h5 {
        font-size: 16px;
      }
    }
    &-box {
      padding: 8px 10px;
      .form-check-input {
        height: 25px;
        width: 25px;
      }
    }
  }
  .px-4pc {
    padding-left: 0pc !important;
    padding-right: 0pc !important;
  }
  .talk-to-expert {
    min-width: 90vw;
    .form .form-control {
      padding: 5px 10px;
    }
  }
  .home-trip-tabs {
    .inner {
      margin: 0 1pc;
    }
  }
  .main-slider-content .title-section {
    top: 50%;
    width: 90%;
  }

  .whatsapp {
    top: auto;
    bottom: 6pc;
    transform: none;
  }

  .adventure-box {
    h5 {
      font-size: 15px;
    }
    .image img {
      height: 95px;
      width: 96px;
    }
  }

  .testimonials-box .content p {
    line-height: normal;
    font-size: 14px;
  }

  .footer {
    padding: 2pc 10px !important;
    &-inner p {
      font-size: 13px;
      line-height: normal;
    }
    .logo {
      margin-bottom: 0;
    }
    ul.social-links {
      margin-top: 5px;
      img {
        height: 20px;
      }
    }

    ul.links {
      li {
        margin-bottom: 0px;
        font-size: 14px;
        a {
          white-space: nowrap;
        }
      }
      &.details li {
        margin-bottom: 3px;
        input.form-control {
          padding: 10px 1pc;
          font-size: 13px;
        }
      }
    }
    .icon img {
      height: 22px;
    }
  }

  .itinerary-top-banner .content {
    h1 {
      font-size: 20px;
      margin-bottom: 1pc;
    }
    ul li {
      font-size: 10px;
      font-weight: 400;
      line-height: normal;
      padding: 7px 10px;
    }
  }

  ul.filter {
    &-1 {
      li,
      .btn {
        font-size: 12px;
      }
      li {
        padding: 0 5px;
      }
    }
    &-2 {
      overflow-x: scroll;
      padding: 10px;
      justify-content: initial;
      li {
        font-size: 12px;
        padding: 5px 1pc;
        line-height: normal;
      }
    }
  }

  .hotel-img-slider,
  .flight-info {
    .heading {
      margin-bottom: 1pc;
      padding-bottom: 5px;
      h4 {
        font-size: 16px;
      }
    }
  }

  .flight-info .box .content {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    ul {
      flex-direction: column;
      img.plane-img {
        width: 30px;
      }
      & > li {
        text-align: center;
        p {
          font-size: 12px;
        }
        &:last-child {
          border-left: 0;
          border-top: 1px solid #797979;
          padding: 10px 0 0;
        }
      }
      &:not(:last-child) {
        border: 0;
        margin: 0;
        padding: 0;
      }
    }
  }

  .edit-option li {
    font-size: 12px;
  }

  ul.initinery-detail-box {
    padding: 0;
    overflow: hidden;
    p {
      font-size: 12px;
      line-height: normal;
    }

    .name-hotel {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      button.btn {
        font-size: 12px;
        padding: 5px 10px;
      }
    }
    & > li {
      gap: 10px;
      &:not(:last-child):before {
        left: 4pc;
      }
    }
    .dot {
      height: 13px;
      width: 13px;
    }
    p.date {
      font-size: 9px;
    }
    .activity {
      h5 {
        font-size: 15px;
      }
      i.small {
        font-size: 12px;
      }
      ul.edit-option {
        flex-direction: column;
        gap: 0px;
        li {
          line-height: normal;
        }
      }
    }
    .box {
      padding: 10px;
    }
    .inclusion-exclusion {
      flex-direction: column;
      gap: 10px;
      h6 {
        font-size: 14px;
      }
      li {
        font-size: 11px;
        align-items: center;
        line-height: normal;
        margin-bottom: 5px;
        .icon {
          font-size: 12px;
          display: flex;
        }
      }
    }
  }

  .itinerary-flight .select-date-origin .btn.btn-lg {
    font-size: 14px;
    padding: 6px 1pc;
    gap: 5px;
  }

  .departing-flights {
    &:not(.transfer-flight) {
      .box .inner-box {
        &.active {
          align-items: center;
          ul.right {
            flex-direction: column;
            li {
              width: 100%;
            }
          }
        }
      }
    }
    .heading {
      flex-direction: column;
      align-items: flex-start;
    }
    .box {
      .left .image {
        height: 70px;
        width: 70px;
      }
      .right {
        justify-content: flex-start;
        row-gap: 5px;
        column-gap: 10px;
        align-items: flex-start;
        position: relative;
        h5 {
          font-size: 15px;
          margin-bottom: 0;
        }
        p {
          font-size: 12px;
        }
        li {
          width: calc(50% - 10px);
          &:last-child {
            position: absolute;
            top: -1pc;
            right: 0;
            width: auto !important;
            .icon {
              font-size: 22px;
            }
          }
        }
        button.btn {
          font-size: 12px;
          padding: 3px 7px !important;
        }
      }
      .inner-box {
        align-items: flex-start;
        gap: 10px;
      }
      .outer-box ul li {
        flex-direction: column;
        gap: 0px;
        .dot,
        &:not(:last-child):before {
          display: none;
        }
        .time {
          color: $red;
        }
        i.small {
          line-height: normal;
        }
        &:first-child {
          border-bottom: 1px solid lightgray;
          padding-bottom: 10px;
          margin-bottom: 10px;
        }
        p.travel-time {
          line-height: normal;
        }
      }
      .transfer-outer {
        p,
        li {
          font-size: 14px;
        }
        ul.location {
          flex-direction: column;
          row-gap: 5px;
          margin: 10px 0;
          h5,
          h6 {
            font-size: 14px;
          }
        }
      }
    }
  }

  .title-section {
    &.bg {
      width: auto;
      margin-bottom: 10px !important;
    }
    .heading + p {
      line-height: normal;
      margin-top: 10px;
    }
  }
  .testimonials-style-2 {
    &-box {
      .image {
        height: 275px;
        margin-bottom: 1pc;
      }
      .right {
        h4.tour-to,
        .content {
          margin: 10px 0;
        }
        .content {
          p {
            font-size: 15px;
          }
        }
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      display: none !important;
    }
  }
  .trending-blog {
    .content {
      padding: 1pc;
    }
    .image {
      height: 250px;
      border-radius: 0;
    }
    .row {
      border-radius: 0;
    }
  }
  .page-banner-2 {
    .image {
      margin: 0;
      &.left {
        height: 400px;
        margin: 0;
      }
      &.right {
        margin-top: 6px;
        height: 75px;
      }
    }
    .row.px-4pc {
      justify-content: center;
    }
  }

  .trip-info ul {
    gap: 10px;
    li {
      width: calc(50% - 10px);
      .icon {
        font-size: 20px;
      }
      h5 {
        font-size: 16px;
        align-items: flex-start;
      }

      p {
        font-size: 14px;
        line-height: normal;
      }
    }
  }
  ul.filter-tabs {
    flex-wrap: nowrap;
    overflow-x: scroll;
    justify-content: initial;
    padding-bottom: 2px !important;
    &::-webkit-scrollbar {
      width: 0px !important;
      height: 0 !important;
    }
    .btn {
      font-size: 14px;
      padding: 8px 15px;
    }
  }

  ul.trip-includes {
    gap: 16px;
    justify-content: space-around;
    li {
      .icon {
        height: 50px;
        width: 50px;
        padding: 10px;
        border-radius: 5px;
      }
      h5 {
        font-size: 12px;
        margin-top: 10px;
      }
    }
  }

  .trip-price-detail {
    padding: 0pc 0 1pc;
  }
  ul.trip-itinerary li {
    span {
      padding: 0px 7px;
      font-size: 12px;
    }
    .title {
      align-items: flex-start;
      & > .d-flex {
        align-items: flex-start !important;
      }
    }
    p {
      line-height: normal;
    }
  }
  .trip-pickup-drop ul {
    gap: 1pc;
    .icon {
      font-size: 25px;
    }
    .line {
      width: 20px;
    }

    h5 {
      font-size: 16px;
    }

    p {
      line-height: normal;
      font-size: 14px;
    }
  }

  .trip-overview {
    ul.quick-info li {
      line-height: normal;
      align-items: flex-start;
    }
    h5 {
      margin-bottom: 5px;
    }
  }

  .trip-all-detail h2.mb-4 {
    margin-bottom: 5px !important;
    font-size: 22px;
  }
  .sign-in {
    overflow-y: scroll;
    .right {
      padding: 1pc;
    }
  }
}
// =================================================================================================

// =================================================================================================
@media only screen and (min-width: 576px) and (max-width: 768px) {
  .best-off-box {
    .image {
      height: 250px;
    }
    .image img,
    .image,
    .content {
      border-radius: 10px;
    }
    &.trending-destination {
      &-left .image {
        height: 200px;
      }
      &-right .image {
        height: 250px;
      }
    }
    &.most-viewed {
      &-right,
      &-left {
        .image {
          height: 200px;
        }
      }
    }
  }

  .timeline {
    .dot {
      height: 20px;
      width: 20px;
    }
    li {
      margin-right: 55px;
      &:not(:last-child) {
        .empty-line,
        .full-filled {
          width: 55px;
        }
      }
      &.half {
        &:last-child .half-filled {
          left: -23px;
        }
        .dot {
          height: 25px;
          width: 25px;
          &:before {
            height: 13px;
            width: 13px;
          }
        }
        .half-filled {
          width: 60px;
          img {
            width: 30px;
          }
        }
      }
    }
  }
  .questions {
    padding: 2pc 1pc 6pc 1pc;

    .title-section .heading {
      line-height: 40px;
    }
    &-inner {
      margin: 2pc 0;
    }
    .search-bar .btn.red-bg {
      font-size: 15px;
    }
    .destination-list .best-off-box {
      width: 170px;
      .image {
        height: 195px;
      }
    }
  }
  .ques-footer button.btn {
    font-size: 17px;
  }
  ul.travel-month li {
    width: calc(33% - 25px);
    padding: 1pc;
  }
  .select-age-box .form-check-input {
    height: 35px;
    width: 35px;
  }
  .travel-purpose li {
    width: calc(50% - (25px));
  }
}
// =================================================================================================

// =================================================================================================
@media only screen and (max-width: 768px) {
  .main-slider-content .title-section {
    .heading {
      font-size: 28px !important;
      margin: 0 !important;
      & + p {
        font-size: 16px !important;
      }
    }
  }

  .testimonials {
    &-box {
      min-height: auto;
      .image {
        margin: 1pc 0;
        img {
          height: 110px;
          width: 110px;
        }
      }
    }
  }

  .main-slider {
    &-tabs {
      display: none;
    }
    &-content {
      padding: 15% 0 0;
      align-items: center;
    }
  }
  .mb-80 {
    margin-bottom: 40px !important;
  }

  .mt-80 {
    margin-top: 40px !important;
  }

  .ptb-80 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .pt-80 {
    padding-top: 40px !important;
  }

  .pb-80 {
    padding-bottom: 40px !important;
  }
  .main-slider {
    height: 100vh;
  }
}
// =================================================================================================

// =================================================================================================
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .best-off-box {
    &.trending-destination {
      &-left .image {
        height: 265px;
      }
      &-right .image {
        height: 300px;
      }
    }
    &.most-viewed-right .image {
      height: 300px;
    }
  }
  .timeline {
    li {
      margin-right: 90px;
      &:not(:last-child) {
        .empty-line,
        .full-filled {
          width: 90px;
        }
      }
      &.half {
        &:last-child .half-filled {
          left: -40px;
        }
        .half-filled {
          width: 85px;
          img {
            width: 35px;
          }
        }
      }
    }
  }
  .questions {
    padding: 2pc 1pc 6pc 1pc;

    .title-section .heading {
      line-height: 40px;
    }
    &-inner {
      margin: 2pc 0;
    }
    .search-bar {
      margin-top: 2pc;
      .btn.red-bg {
        font-size: 15px;
      }
    }
    .destination-list .best-off-box {
      width: calc(20% - 15px);
      .content {
        p {
          display: none;
        }
        h4 {
          font-size: 18px;
          font-weight: 300;
          letter-spacing: 1px;
        }
      }
      .image {
        height: 170px;
      }
    }
  }
  .ques-footer button.btn {
    font-size: 17px;
  }
  ul.travel-month li {
    width: calc(25% - 25px);
    padding: 1pc;
  }
  .select-age-box {
    padding: 10px 1pc;
    .form-check-input {
      height: 35px;
      width: 35px;
    }
  }
  .travel-purpose li {
    width: calc(25% - 20px);
    margin: 0 20px 20px 0px;
    img {
      height: 105px;
    }
    h5 {
      font-size: 16px;
    }
  }
  .main-slider {
    &-content {
      padding-bottom: 20%;
    }
    &-tabs {
      .btn.red-bg {
        font-size: 15px;
      }
      ul.tabs {
        gap: 10px;
        button img {
          width: 30px;
        }
      }
      .tab-content {
        .travel-info {
          h5 {
            font-size: 17px;
            margin-bottom: 7px;
          }
          & > li {
            padding: 0 10px;
            p.text {
              line-height: normal;
            }
          }
        }
        .traveller {
          width: auto !important;
          ul.number {
            li {
              padding: 2px 10px;
            }
            &.travel-class li {
              padding: 5px 10px;
            }
          }
        }
      }
    }
  }
}
// =================================================================================================

// =================================================================================================
@media only screen and (max-width: 992px) {
  header {
    .navbar {
      .dropdown {
        &-menu {
          padding: 0;
          border-radius: 0;
          border: 0;
        }
      }
    }
    &.change-header .navbar .nav-link {
      color: #000000 !important;
    }
  }
  .offcanvas {
    &-header {
      .btn-close {
        background-color: #ffffff;
        border-radius: 0;
        filter: invert(1) saturate(0.5);
      }
    }
    .navbar-nav {
      align-items: start;
      justify-content: start;
      gap: 0;
      li.nav-item {
        width: 100%;
        a {
          letter-spacing: 0ch;
          border-bottom: 1px solid #e3dede;
          font-weight: 400;
          padding: 10px 1pc;
        }
      }
    }
  }
  .testimonials {
    padding: 2pc 2pc 1pc;
  }
  .awards {
    padding: 2pc;
  }
  .test-award-mid {
    height: 370px;
  }
  .questions .main-logo {
    height: 40px;
  }
}
// =================================================================================================

// =================================================================================================

@media only screen and (min-width: 992px) {
  header .navbar {
    .dropdown {
      &-menu {
        display: block !important;
        visibility: hidden;
        opacity: 0;
        transition: all 0.5s;
        padding: 0;
        min-width: 210px;
        border-top: 4px solid #ff5355;
        li:not(:last-child) a.dropdown-item {
          border-bottom: 1px solid #ddd;
        }
      }
      &-item {
        padding: 10px 1pc;
        &:hover {
          background: #ffa7a7a8;
          color: #ff5355;
        }
      }
      &-toggle::after {
        display: none;
      }
    }
    li.nav-item.dropdown:hover ul.dropdown-menu {
      visibility: visible;
      opacity: 1;
      transition: all 0.5s;
    }
  }
}

// =================================================================================================

// =================================================================================================
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .packages-left {
    width: 24%;
  }
  .best-off-box {
    .image {
      height: 250px;
    }
    .image img,
    .image,
    .content {
      border-radius: 10px;
    }
    &.most-viewed {
      &-right,
      &-left {
        .image {
          height: 250px;
        }
      }
    }
  }
  .test-award-mid {
    height: 450px;
  }
  .questions {
    padding: 2pc 2pc 5pc 2pc;
    &-inner {
      margin: 2pc 0 1pc;
    }
    .destination-list .best-off-box {
      width: calc(20% - 15px);
      .content {
        p {
          display: none;
        }
        h4 {
          font-size: 18px;
          font-weight: 300;
          letter-spacing: 1px;
        }
      }
      .image {
        height: 170px;
      }
    }
  }
  ul.travel-month li {
    width: calc(16% - 15px);
    padding: 1pc;
    margin: 0 15px 15px 0px !important;
    p {
      font-size: 14px;
    }
    img {
      height: 30px;
      margin: 10px 0;
    }
    h5 {
      font-size: 16px;
    }
  }
  .travel-purpose li {
    width: calc(25% - 20px);
    margin: 0 20px 20px 0px;

    h5 {
      font-size: 16px;
    }
  }
  .select-age-box {
    padding: 10px 1pc;
    .form-check-input {
      height: 35px;
      width: 35px;
    }
  }
}
// =================================================================================================

// =================================================================================================
@media only screen and (max-width: 1200px) {
  .packages-inner {
    padding: 0;
    &-left {
      border-radius: 10px 10px 0 0;
      img {
        border-radius: inherit;
      }
    }
    &-right {
      text-align: center;
      .heading {
        justify-content: center;
        margin: 0;
      }
      p.price {
        justify-content: center;
        line-height: normal;
      }

      ul.detail {
        justify-content: center;
      }
      .total-review,
      ul.offer,
      .itinarary {
        display: none !important;
      }
    }
  }
  .footer {
    margin: 0 3pc;
    padding-left: 1pc !important;
    padding-right: 1pc !important;
    padding: 3pc 0;
    h4.heading {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
  header .navbar {
    margin: 10px 1pc;
  }
  .px-4pc {
    padding-left: 2pc;
    padding-right: 2pc;
  }
  .adventure .title-section {
    background: #fff !important;
    text-align: center;
    justify-content: center;
    height: auto;
    padding: 0;
    margin-bottom: 2pc;
    h2.heading {
      color: #000 !important;
    }
  }
}
// =================================================================================================

// =================================================================================================
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .packages-left {
    width: 22%;
  }
  .footer {
    margin: 0 3pc;
    padding-left: 2pc;
    padding-right: 2pc;
  }
  .best-off-box {
    .image {
      height: 275px;
    }
    .image img,
    .image,
    .content {
      border-radius: 10px;
    }
    &.most-viewed-right .image {
      height: 250px;
    }
  }
  .test-award-mid {
    height: 450px;
  }
  .questions {
    &-inner {
      margin: 2pc 0;
      .destination-list {
        margin: 0 3pc;
        .best-off-box .image {
          height: 200px;
        }
      }
    }
  }
  ul.travel-month li {
    width: calc(16% - 15px);
    padding: 1pc;
    margin: 0 15px 15px 0px !important;
    p {
      font-size: 14px;
    }
    img {
      height: 30px;
      margin: 10px 0;
    }
    h5 {
      font-size: 16px;
    }
  }
  .travel-purpose li {
    width: calc(25% - 20px);
    margin: 0 20px 20px 0px;

    h5 {
      font-size: 16px;
    }
  }
  .select-age-box {
    padding: 10px 1pc;
    .form-check-input {
      height: 35px;
      width: 35px;
    }
  }
}
// =================================================================================================

// =================================================================================================
@media only screen and (min-width: 1400px) and (max-width: 1700px) {
  .questions-inner {
    margin: 2pc 0;
    .destination-list {
      margin: 0 6pc;
    }
  }
  .footer {
    margin: 0 3pc;
  }
}
// =================================================================================================

// =================================================================================================
@media only screen and (min-width: 1400px) {
  ul.travel-purpose {
    margin: 0 8pc;
  }
}
// =================================================================================================

// =================================================================================================
@media only screen and (min-width: 1700px) {
  ul.travel-purpose {
    margin: 0 12pc;
  }
  .questions-inner .destination-list {
    margin: 0 14pc;
  }
  .main-slider-tabs {
    margin: 0 5pc;
  }
}
// =================================================================================================
